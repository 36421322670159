import React from "react";
import { useNavigate } from "react-router-dom";
import { cretateTestMemoDetails } from "./commonHandlerFunction/testMemoFunctionHandler";
import { encryptDataForURL } from "../../utills/useCryptoUtils";
import { GetTenantDetails } from "../../services/commonServices";

const ListingActionButton = ({
  status,
  actions,
  user,
  moduleType,
  jrf_id,
  sampleInwardFormId,
  row,
  setIsRejectPopupOpen,
  setJRFCreationType,
  setIsPopupOpen,
  setIsOverlayLoader,
}) => {
  const navigate = useNavigate();
  const actionBtnList = {
    jrf: [
      {
        btnStatus: "posted",
        label: "Verify Checklist",
        status: "checklist",
        btnshortName: "verifyCheckList",
      },
      {
        btnStatus: "accepted",
        label: "Sample Inward",
        status: "inward",
        btnshortName: "sampleInward",
      },
      {
        btnStatus: "completed",
        label: "Test Report",
        status: "testReport",
        btnshortName: "testReport",
      },
      {
        btnStatus: "rejected",
        label: "Update",
        status: "rejected",
        btnshortName: "Edit",
      },
      {
        btnStatus: "saved",
        label: "Update",
        status: "saved",
        btnshortName: "Saved",
      },
    ],
    sampleinward: [
      {
        btnStatus: "inwarded",
        label: "Assignment",
        status: "assignment",
        btnshortName: "assignment",
      },
      {
        btnStatus: "assigning",
        label: "Assignment",
        status: "assignment",
        btnshortName: "assignment",
      },
      {
        btnStatus: "created",
        label: "Sample Inward",
        status: "inward",
        btnshortName: "sampleInward",
      },
      {
        btnStatus: "saved",
        label: "Sample Inward",
        status: "inward",
        btnshortName: "sampleInward",
      },
      {
        btnStatus: "assigned",
        label: "Create Test Memo",
        status: "testMemo",
        btnshortName: "testMemo",
      },
      {
        btnStatus: "certified",
        label: "Test Report",
        status: "certified",
        btnshortName: "testReport",
      },
    ],
    allotment: [
      {
        btnStatus: "pending",
        label: "Allot",
        status: "allot",
        btnshortName: "allot",
      },
      {
        btnStatus: "allotted",
        label: "Update",
        status: "allotted",
        btnshortName: "Edit",
      },
      {
        btnStatus: "allotted",
        label: "Verification",
        status: "verification",
        btnshortName: "verification",
      },
    ],
    testmemomain: [
      {
        btnStatus: "pending",
        label: "Verify Test Memo",
        status: "pending",
        btnshortName: "verifytestmemo",
      },
      {
        btnStatus: "posted",
        label: "Allot",
        status: "posted",
        btnshortName: "create-allot",
      },
      {
        btnStatus: "created",
        label: "Send to Lab",
        status: "sendToLab",
        btnshortName: "sendToLab",
      },
      {
        btnStatus: "results",
        label: "Verify Test Result",
        status: "results",
        btnshortName: "verifytestresult",
      },
      {
        btnStatus: "verified",
        label: "Test Report",
        status: "verified",
        btnshortName: "testmemoresult",
      },
      {
        btnStatus: "rejected",
        label: "Update",
        status: "rejected",
        btnshortName: "Edit",
      },
    ],
    sampleverification: [
      {
        btnStatus: "pending",
        label: "Verification",
        status: "verification",
        btnshortName: "verification",
      },
    ],
    sfm: [
      {
        btnStatus: "pending",
        label: "Result",
        status: "SFMResult",
        btnshortName: "SFMResult",
      },
      {
        btnStatus: "in-process",
        label: "Result",
        status: "SFMResult",
        btnshortName: "SFMResult",
      },
      {
        btnStatus: "rejected",
        label: "Update",
        status: "SFMUpdate",
        btnshortName: "SFMUpdate",
      },
    ],
    internalcertificate: [
      {
        btnStatus: "dtm-approved",
        label: "Verify",
        status: "verifyByTm",
        btnshortName: "verifyByTm",
      },
      {
        btnStatus: "pending",
        label: "Verify",
        status: "verifyByDTM",
        btnshortName: "verifyByDTM",
      },

      {
        btnStatus: "tm-approved",
        label: "Publish",
        status: "publishBtn",
        btnshortName: "publishBtn",
      },
      {
        btnStatus: "dtm-reject",
        label: "Update",
        status: "dtmReject",
        btnshortName: "dtmReject",
      },
      {
        btnStatus: "tm-reject",
        label: "Update",
        status: "tmReject",
        btnshortName: "tmReject",
      },
    ],
    jobinstruction: [
      {
        btnStatus: "saved",
        label: "Scope Of Work",
        status: "saved",
        btnshortName: "saved",
      },
      {
        btnStatus: "created",
        label: "Analysis",
        status: "created",
        btnshortName: "created",
      },
      {
        btnStatus: "pre-analysis",
        label: "Analysis",
        status: "pre-analysis",
        btnshortName: "pre-analysis",
      },
      {
        btnStatus: "analysis",
        label: "Nominate",
        status: "analysis",
        btnshortName: "analysis",
      },
      {
        btnStatus: "rejected",
        label: "Update",
        status: "rejected",
        btnshortName: "rejected",
      },
      {
        btnStatus: "posted",
        label: "Verify",
        status: "posted",
        btnshortName: "posted",
      },
    ],
    jioperationjsonb: [
      {
        btnStatus: "posted",
        label: "Verify",
        status: "posted",
        btnshortName: "posted",
      },
      {
        btnStatus: "accepted",
        label: "Process",
        status: "accepted",
        btnshortName: "accepted",
      },
    ],
    TPIMain: [
      {
        btnStatus: "created",
        label: "Update",
        status: "created",
        btnshortName: "created",
      },
    ],
  };

  const rolesWiseBtnList = {
    jrf: {
      BU: ["testReport", "Edit", "Saved"],
      LR: ["testReport", "verifyCheckList", "sampleInward", "Edit", "Saved"],
      TM: [],
      STM: [],
      QM: [],
      SQM: [],
      SLC: [],
      LC: [],
      DTM: [],
    },
    sampleinward: {
      BU: ["testReport"],
      LR: ["assignment", "testMemo", "testReport", "sendToLab", "sampleInward"],
      TM: ["verifytestmemo"],
      STM: ["verifytestmemo"],
      QM: ["verifytestmemo"],
      SQM: ["verifytestmemo"],
      SLC: ["verifytestmemo"],
      LC: [],
      DTM: [],
    },
    allotment: {
      BU: ["testReport"],
      LR: ["assignment", "testMemo", "certified"],
      TM: ["verifytestmemo", "Edit"],
      STM: ["verifytestmemo", "Edit"],
      QM: ["verifytestmemo", "Edit"],
      SQM: ["verifytestmemo", "Edit"],
      SLC: ["verifytestmemo", "verification"],
      LC: ["verification"],
      DTM: ["verifytestmemo", "verification"],
    },
    sampleverification: {
      BU: [],
      LR: [],
      TM: [],
      STM: [],
      QM: [],
      SQM: [],
      SLC: ["verification"],
      LC: ["verification"],
      DTM: ["verification"],
    },
    testmemomain: {
      BU: [],
      LR: ["sendToLab", "testmemoresult", "Edit"],
      TM: ["verifytestmemo", "create-allot", "verifytestresult"],
      STM: ["verifytestmemo", "create-allot", "verifytestresult"],
      QM: ["verifytestmemo", "create-allot", "verifytestresult"],
      SQM: ["verifytestmemo", "create-allot", "verifytestresult"],
      SLC: ["verifytestmemo", "verifytestresult"],
      LC: ["verification"],
      DTM: ["create-allot"],
    },
    sfm: {
      BU: [],
      LR: [],
      TM: [],
      STM: [],
      QM: [],
      SQM: [],
      SLC: ["SFMResult", "SFMUpdate"],
      LC: ["SFMResult", "SFMUpdate"],
      DTM: ["SFMResult", "SFMUpdate"],
    },
    internalcertificate: {
      BU: [],
      LR: ["publishBtn", "dtmReject", "tmReject"],
      TM: ["verifyByTm", "verifyByDTM"],
      STM: ["verifyByTm"],
      QM: ["verifyByTm"],
      SQM: ["verifyByTm"],
      SLC: [],
      LC: [],
      DTM: ["verifyByDTM"],
    },
    jobinstruction: {
      BU: ["posted", "accepted", "process"],
      LR: [],
      TM: [],
      STM: [],
      QM: [],
      SQM: [],
      SLC: [],
      LC: [],
      DTM: [],
      "OPS ADMIN": ["saved", "created", "analysis", "pre-analysis", "rejected"],
    },
    jioperationjsonb: {
      BU: ["posted", "accepted", "process"],
      LR: [],
      TM: [],
      STM: [],
      QM: [],
      SQM: [],
      SLC: [],
      LC: [],
      DTM: [],
      "OPS ADMIN": [],
    },
    TPIMain: {
      BU: ["created"],
      LR: [],
      TM: [],
      STM: [],
      QM: [],
      SQM: [],
      SLC: [],
      LC: [],
      DTM: [],
      "OPS ADMIN": [],
    },
  };

  const getBtnListArray = () => {
    let btnFilteredData = [];
    btnFilteredData = actionBtnList[moduleType].filter((btn) => {
      if (
        GetTenantDetails(1, 1) === "TPBPL" &&
        moduleType === "allotment" &&
        user?.role === "LC"
      ) {
        return false;
      }
      return rolesWiseBtnList[moduleType]?.[user?.role].includes(
        btn.btnshortName
      );
    });
    return btnFilteredData;
  };
  //table Action buttion handler
  const handleOnclick = (action) => {
    if (moduleType === "sampleinward") {
      if (action.status === "testMemo") {
        cretateTestMemoDetails(
          sampleInwardFormId,
          action,
          navigate,
          setIsOverlayLoader
        );
      } else if (action?.status == "testReport") {
        navigate(
          action?.redirectUrl +
            "/" +
            encryptDataForURL(row["internal_certificate_id"])
        );
      } else {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&sampleInwardId=" +
            encryptDataForURL(sampleInwardFormId) +
            "&id=" +
            encryptDataForURL(jrf_id)
        );
      }
    } else if (moduleType === "allotment") {
      navigate(
        action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&sampleAllotmentId=" +
          encryptDataForURL(row["sa_id"])
      );
    } else if (moduleType === "sampleverification") {
      navigate(
        action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&sampleVarificationId=" +
          encryptDataForURL(row["sv_id"])
      );
    } else if (moduleType === "testmemomain") {
      if (
        action.status === "create-allot" ||
        action.status === "sendToLab" ||
        action.status === "verifytestmemo"
      ) {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&testMemoId=" +
            encryptDataForURL(row["tm_id"])
        );
      } else if (action.status == "verifytestresult") {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&testMemoId=" +
            encryptDataForURL(row["tm_id"])
        );
      } else if (action.status == "testmemoresult") {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&testMemoId=" +
            encryptDataForURL(row["tm_id"])
        );
      } else if (action.status == "Edit") {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&testMemoId=" +
            encryptDataForURL(row["tm_id"])
        );
      }
    } else if (moduleType === "sfm") {
      navigate(
        action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&sfmid=" +
          encryptDataForURL(row["sfm_id"])
      );
    } else if (moduleType === "internalcertificate") {
      localStorage.setItem("icId", encryptDataForURL(row["ic_id"]));
      if (action?.status == "approveByDTM") {
        setIsPopupOpen(true);
        setJRFCreationType("approve");
      } else if (action?.status == "rejectByDTM") {
        setIsRejectPopupOpen(true);
      } else {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&editId=" +
            encryptDataForURL(row["ic_id"])
        );
      }
    } else if (moduleType == "jobinstruction") {
      if (action.recordType) {
        navigate(
          action?.redirectUrl +
            "/" +
            encryptDataForURL(row["ji_id"]) +
            "/" +
            encryptDataForURL(action.recordType)
        );
      } else if (action.status === "posted") {
        navigate(
          action?.redirectUrl +
            "/" +
            encryptDataForURL(row["ji_id"]) +
            "?action=" +
            encryptDataForURL("View") +
            "&status=" +
            encryptDataForURL(action?.status)
        );
      } else {
        navigate(action?.redirectUrl + "/" + encryptDataForURL(row["ji_id"]));
      }
    } else if (moduleType == "jioperationjsonb") {
      navigate(
        action?.redirectUrl +
          "/" +
          encryptDataForURL(row["ji_id"]) +
          "?action=" +
          encryptDataForURL("View") +
          "&status=" +
          encryptDataForURL(action?.status)
      );
    } else if (moduleType === "TPIMain") {
      navigate(
        `/operation/vessel-ji-list/other-tpi/${encryptDataForURL(
          row["fk_jiid"]
        )}/${encryptDataForURL("otherTPI")}/${encryptDataForURL(
          row["fk_jis_id"]
        )}/${encryptDataForURL(
          row["tpi_id"]
        )}?OperationType=${encryptDataForURL(
          row["activity_name"]
        )}&operationId=${encryptDataForURL(
          row["fk_jis_id"]
        )}`
      );
    } else {
      if (action?.status == "testReport") {
        navigate(
          action?.redirectUrl +
            "/" +
            encryptDataForURL(row["internal_certificate_id"])
        );
      } else {
        if (row["jrf_status"] === "saved") {
          if (row["jrf_is_ops"]) {
            navigate(
              "/jrfListing/operation-jrf?status=" +
                encryptDataForURL("Edit") +
                "&id=" +
                encryptDataForURL(jrf_id)
            );
          } else {
            navigate(
              "/jrfListing/jrf?status=" +
                encryptDataForURL("Edit") +
                "&id=" +
                encryptDataForURL(jrf_id)
            );
          }
        } else if (row["jrf_is_ops"] && row["jrf_status"] === "posted") {
          navigate(
            "/jrfListing/operation-inwardForm-checklist?status=" +
              encryptDataForURL("checklist") +
              "&id=" +
              encryptDataForURL(jrf_id)
          );
        } else if (
          user?.role === "LR" &&
          status === "rejected" &&
          row["jrf_is_external"]
        ) {
          navigate(
            "/jrfListing/external-jrf?status=" +
              encryptDataForURL(action?.status) +
              "&id=" +
              encryptDataForURL(jrf_id)
          );
        } else {
          navigate(
            action?.redirectUrl +
              "?status=" +
              encryptDataForURL(action?.status) +
              "&id=" +
              encryptDataForURL(jrf_id)
          );
        }
      }
    }
  };
  const checkRoleBseCondition = () => {
    if (moduleType === "jrf") {
      if (
        user?.role === "LR" &&
        status === "rejected" &&
        !row["jrf_is_external"]
      ) {
        return false;
      }
    }
    else if(moduleType === "allotment"){
      if (user?.role === "DTM") {
        if(user?.logged_in_user_info?.usr_id!==row['sa_sampleallottedto']){
          return false;
        }
      }
    }
    return true;
  };
  const chkBtnExists = (action) => {
    const validConditions = user?.role ? getBtnListArray() : [];

    const filterData = validConditions.filter((condition) => {
      if (!checkRoleBseCondition()) {
        return false;
      }
      if (action.isDupplicate) {
        return (
          condition.btnStatus === status &&
          condition.btnshortName === action?.status
        );
      } else {
        return (
          condition.btnStatus === status && condition.label === action?.label
        );
      }
    });
    return Boolean(filterData.length > 0);
  };
  return actions.map((action, actionIndex) => {
    const isValid = status && chkBtnExists(action);

    if (!isValid || action?.type === "icon") {
      return null;
    }
    return (
      <button
        type="button"
        key={"listing-action" + actionIndex}
        className="iconBtn"
        onClick={() => handleOnclick(action)}
      >
        {action?.label}
      </button>
    );
  });
};

export default ListingActionButton;
