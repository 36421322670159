import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  handleFormPost,
  handleFormSave,
} from "../commonHandlerFunction/JRFHandlerFunctions";
import React from "react";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

const CommercialCertificateButtons = ({
  useFor,
  status,
  ApproveCertificate,
  sendForApproval,
  dailyReportInDocument
}) => {
  const { t } = useTranslation();
  const translate = t;
  let navigate = useNavigate();
  return (
    <div className="submit_btns">
      <button
        className="cancelBtn"
        type="button"
        onClick={() => navigate(-1)}
      >
        Back
      </button>
      {(status === "approve" || status === "NonLMSApprove") && (
        <button
          className="submitBtn"
          type="button"
          onClick={() => ApproveCertificate()}
        >
          Approve
        </button>
      )}

      {(status === "posted" || status === "NonLMSPosted") && (
        <button
          className="submitBtn"
          type="button"
          onClick={() => sendForApproval()}
        >
          Send for Approval
        </button>
      )}
      { }

      {
        useFor === "daily_report" ? (
          <button
            className="submitBtn"
            type="button"
            onClick={() =>
              dailyReportInDocument()
            }
          >
            Share Daily Report
          </button>
        ) :
          status !== "posted" &&
          status !== "approve" &&
          status !== "NonLMSPosted" &&
          status !== "NonLMSApprove" && (
            <button
              className="submitBtn"
              type="button"
              onClick={() =>
                navigate("/operation/commercial-certificate-list")
              }
            >
              Go to certificates
            </button>
          )}
    </div>
  );
};


CommercialCertificateButtons.propTypes = {
  setIsPopupOpen: PropTypes.func,
  setJRFCreationType: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleBackButtonFunction: PropTypes.func,
  viewOnly: PropTypes.bool,
};

export default CommercialCertificateButtons;
