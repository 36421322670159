import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const InputSelectwithSearch = ({ field }) => {
  let {
    name,
    label,
    value,
    onChange,
    required,
    options,
    error,
    fieldWidth,
    masterOptions,
    customname,
    actionClicked,
    from,
    isCustomOptions,
    customOptions,
    exludeOptions,
  } = field;

  const [newOptions, setNewOptions] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    if (isCustomOptions) {
      setNewOptions(customOptions);
    } else {
      masterOptions?.forEach((model, index) => {
        if (model.model === name || model.model === customname) {
          if (exludeOptions && exludeOptions.length > 0) {
            let filteroption = model.data.filter((option) => {
              return !exludeOptions.includes(option.id.toString());
            });

            setNewOptions(filteroption);
          } else {
            setNewOptions(model.data);
          }
        }
      });
    }
  }, [options, newOptions, name, masterOptions]);

  const [errorMsg, setErrorMsg] = useState(false);
  useEffect(() => {
    if ((value === undefined || value === "") && required) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
    setSelectedOptions(value);
  }, [value]);

  const getSelectedOptionName = () => {
    var selectedOptions=newOptions.filter((option) => {
      return value==option.id
    });
    return selectedOptions ? selectedOptions[0] : null
  };
  return (
    <div className="form-group my-2">
      {from !== "Table" && label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      <div className={"w-" + (fieldWidth ?? "75") + " d-inline-block mx-2"}>
        <Select
          className="form-control rounded-2"
          classNamePrefix="select"
          isSearchable={true}
          name={name || label}
          options={newOptions}
          onChange={onChange}
          defaultValue={getSelectedOptionName()}
        />
        {errorMsg && actionClicked ? (
          <p className="text-danger errorMsg">{label} is Required</p>
        ) : null}
        {error && actionClicked && <p className="text-danger">{error}</p>}
      </div>
    </div>
  );
};

InputSelectwithSearch.propTypes = {
  field: PropTypes.object.isRequired,
};

export default InputSelectwithSearch;
