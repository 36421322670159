
import React, { useEffect } from "react";
import PropTypes from 'prop-types';

const InputRadio = ({ field }) => {
  const {
    name,
    label,
    error,
    tooltip,
    characterLimit,
    options,
    required,
    fieldWidth,
    onChange,
    value,
    actionClicked,
    viewOnly,
    defaultValue
  } = field;

  useEffect(() => {
    if (defaultValue && !value) {
      onChange(defaultValue, 1);
    }
  }, [options]);
  let newVal = value;
  if (name === "jrf_is_lab_capable" || name === "jrf_agrees_with_time") {
    if (value === "Yes" || value === true) {
      newVal = "Yes";
    } else {
      newVal = "No";
    }
  }
  return (
    <div className="form-group my-2 listform">
           {label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      <div className={"w-" + (fieldWidth ?? "125") + " mx-2 radioOptions"}>
        {options.map((option, optionIndex) => (
          <span className="radioOption" key={"radio" + optionIndex}>
            <input
              type="radio"
              id={name}
              name={name}
              value={option}
              title={tooltip}
              maxLength={characterLimit}
              onChange={onChange}
              checked={option === newVal}
              disabled={viewOnly}
            />
            {!field.isNoLabel && <label htmlFor={name}>{option}</label>}
          </span>
        ))}
        {error && actionClicked && <p className="text-danger">{error}</p>}
      </div>
    </div>
  );
};


InputRadio.propTypes = {
  field: PropTypes.object.isRequired,
};

export default InputRadio;
