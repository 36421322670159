import React from "react";
import PropTypes from "prop-types";

const InputLabel = ({ field }) => {
  const {
    label,
    value,
    fieldWidth,
    headerLength,
    name,
    onChange,
    required,
    placeholder,
    tooltip,
    characterLimit,
    validation,
    styleName,
    centerAlign,
    upperClass,
    isForOnlyLabel,
  } = field;
  return (
    <div
      className={
        (centerAlign ? "form-group " : "form-group my-2 ") +
        upperClass +
        (styleName !== " InputNum" ? " my-2" : "")
      }
      style={{ position: "relative" }}
    >
      {label && (
        <label
          htmlFor={label}
          style={{ width: headerLength ? `${headerLength}` : "18%" }}
          className={styleName}
        >
          {label}{" "}
        </label>
      )}
      {!isForOnlyLabel ? (
        value ? (
          <div
            className={"w-" + (fieldWidth ?? "75") + " d-inline-block mx-2 "}
          >
            <input
              type="text"
              id={name || label}
              name={name || label}
              value={value}
              onChange={onChange}
              required={required}
              placeholder={placeholder}
              className={"form-control rounded-2 labelInput " + styleName}
              readOnly={true}
              title={tooltip}
              maxLength={characterLimit}
              pattern={validation?.pattern}
            />
          </div>
        ) : (
          <div
            className={
              "w-" + (fieldWidth ?? "75") + " d-inline-block mx-2  labelValue"
            }
          >
            {value}
          </div>
        )
      ) : null}
    </div>
  );
};

InputLabel.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fieldWidth: PropTypes.number,
    headerLength: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    tooltip: PropTypes.string,
    characterLimit: PropTypes.number,
    validation: PropTypes.func,
    styleName: PropTypes.string,
    upperClass: PropTypes.string,
    centerAlign: PropTypes.bool,
    upperClass: PropTypes.string,
  }).isRequired,
};

export default InputLabel;
