import { toast } from "react-toastify";
import {
  checkexistingCertinoApi,
  dsSurveyPdfApi,
  getDSApi,
  getHHApi,
  getSVApi,
  H_H_PdfApi,
  supervisionPdfApi,
} from "../../../../services/api";
import { postDataFromApi } from "../../../../services/commonServices";

export const checkCettificateNoAvailibility = async (
  value,
  setIsOverlayLoader,
  setIsValideValue
) => {
  let ref_regex = /^(?=.*[0-9])(?=.*[A-Za-z])[0-9A-Za-z]{16}$/;
  if (value.length != 16) {
    return;
  } else if (!ref_regex.test(value)) {
    toast.error("Please enter valid value", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return;
  }
  try {
    let payload = {
      certificate_number: value,
    };
    setIsOverlayLoader(true);
    let res = await postDataFromApi(checkexistingCertinoApi, payload);
    if (res?.data?.status === 200) {
      setIsValideValue(true);
      toast.success(res.data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setIsValideValue(false);
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
  } finally {
    setIsOverlayLoader(false);
  }
};

export const getNonLMSDetailsById = async (OperationType, payload) => {
  let responseData;
  if (OperationType === "H&H") {
    responseData = await postDataFromApi(getHHApi, payload);
    if (responseData.data.status === 200) {
      responseData.data.data.opsvd_id = responseData.data.data.opsvhh_id;
    }
  } else if (OperationType === "Supervision") {
    responseData = await postDataFromApi(getSVApi, payload);
    if (responseData.data.status === 200) {
      responseData.data.data.opsvd_id = responseData.data.data.opsvsv_id;
    }
  } else {
    responseData = await postDataFromApi(getDSApi, payload);
  }

  return responseData;
};

export const downLoadNonLMSCertificatePDFById = async (
  OperationType,
  OPSId,
  CCID
) => {
  let responseData;
  let payload;
  if (OperationType === "H&H") {
    payload = {
      hatch_hold_id: OPSId,
      cc_id: CCID,
      
    };
    responseData = await postDataFromApi(H_H_PdfApi, payload, "", true, "", "");
  } else if (OperationType === "Supervision") {
    payload = {
      supervision_id: OPSId,
      // cc_id: CCID,

    };
    responseData = await postDataFromApi(
      supervisionPdfApi,
      payload,
      "",
      true,
      "",
      ""
    );
  } else {
    payload = {
      opsvd_id: OPSId,
      cc_id: CCID,

    };
    responseData = await postDataFromApi(
      dsSurveyPdfApi,
      payload,
      "",
      true,
      "",
      ""
    );
  }

  return responseData;
};
