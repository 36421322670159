import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteDataFromApi,
  postDataFromApi,
  putDataFromApi,
} from "../../services/commonServices";
import {
  getFormatedDate,
  isModuelePermission,
} from "../../services/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmation from "./DeleteConfirmation";
import {
  InternalCertificateDeleteApi,
  JRFDeleteApi,
  JRFPDFDownloadApi,
  SFMDeleteApi,
  allotmentDeleteApi,
  sampleInwardDetailsDeleteAPI,
  sampleverificationDeleteApi,
  testMemoDeleteApi,
} from "../../services/api";
import { historyData } from "../../actions/authActions";
import PropTypes from "prop-types";
import { encryptDataForURL, decryptDataForURL } from "../../utills/useCryptoUtils";
import { handleJobInstructionDelete } from "./commonHandlerFunction/jobinstructionHandlerFunctions";
import { getNonLMSDetailsById } from "./commonHandlerFunction/OPscertificate/OPSCertificateHandlerFunctions";
import { handleVesselOperationDelete, handleDocumentDelete } from "./commonHandlerFunction/CommercialCertificateHandlerFunctions";


const PopupOptions = ({
  popupActions,
  setPopupIndex,
  id,
  row,
  section,
  getAllListingData,
  formConfig,
  type,
  sampleInwardFormId,
  model,
  isBottom,
  status,
  setDontClick,
  from,
  setPopupType,
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let user;
  const session = useSelector((state) => state.session);
  user = session?.user;

  let rolePermissions;
  rolePermissions = session?.user?.permissions;

  const hash = window.location.hash;
  const params = new URLSearchParams(hash.split("?")[1]);
  const OperationType = decryptDataForURL(params.get("OperationType"));

  const [actions, setActions] = useState(popupActions);
  const [isDelete, setIsDelete] = useState(false);
  const [actionType, setActionType] = useState(false);
  const moduleType = formConfig?.listView?.moduleType;
  const subModuleType = formConfig?.listView?.subModuleType;
  const handleClick = async (value, actionType = "") => {
    setActionType(value);
    setDontClick(true);

    if (moduleType === "sampleinward") {
      if (actionType === "View") {
        navigate(
          `/inwardList/inwardForm?status=${encryptDataForURL(
            value
          )}&id=${encryptDataForURL(id)}&sampleInwardId=${encryptDataForURL(
            sampleInwardFormId
          )}&action=${encryptDataForURL("View")}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Inward No " + row.smpl_inward_number,
          model: model,
          redirect: "/inwardList",
          Breadcrumb: "Sample Inward List",
        };
        dispatch(historyData(historyDetails));
        navigate(
          `/module-history?status=${encryptDataForURL(
            value
          )}&id=${encryptDataForURL(row["smpl_inwrd_id"])}`
        );

        setPopupIndex(-1);
      } else if (value === "assignmentview") {
        navigate(
          "/inwardList/groupAssignmentPreview?status=" +
          encryptDataForURL("assignment") +
          "&sampleInwardId=" +
          encryptDataForURL(sampleInwardFormId) +
          "&id=" +
          encryptDataForURL(id)
        );
      } else if (value === "Delete") {
        setIsDelete(true);
      } else {
        navigate(
          `/inwardList/inwardForm?status=${encryptDataForURL(
            value
          )}&id=${encryptDataForURL(id)}&sampleInwardId=${encryptDataForURL(
            sampleInwardFormId
          )}&action=${encryptDataForURL("Edit")}`
        );
      }
    } else if (moduleType === "testmemomain") {
      if (value === "Delete") {
        setIsDelete(true);
      } else if (value === "View") {
        navigate(
          `/testmemoList/testmemo?view=${encryptDataForURL(
            "view"
          )}&status=${encryptDataForURL(
            "testMemo"
          )}&testMemoId=${encryptDataForURL(row["tm_id"])}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Test Memo No " + row.tm_number,
          model: model,
          redirect: "/testmemoList",
          Breadcrumb: "Test Memo List",
        };
        dispatch(historyData(historyDetails));
        navigate(
          `/module-history?status=${encryptDataForURL(
            value
          )}&id=${encryptDataForURL(row["tm_id"])}`
        );

        setPopupIndex(-1);
      } else if (value === "PDF") {
        const historyDetails = {
          recordNo: "Test Memo No " + row.tm_number,
          model: model,
          redirect: "/testmemoList",
          Breadcrumb: "Test Memo List",
        };
        dispatch(historyData(historyDetails));
        const encryptedValue = encryptDataForURL(value);
        const encryptedId = encryptDataForURL(row["tm_id"]);
        navigate(
          `/testmemoList/testmemoPDF?status=${encryptedValue}&id=${encryptedId}`
        );

        setPopupIndex(-1);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (moduleType === "allotment") {
      if (value === "View") {
        navigate(
          `/allotmentList/allotment?view=${encryptDataForURL(
            "view"
          )}&status=${encryptDataForURL(
            "allotment"
          )}&sampleAllotmentId=${encryptDataForURL(row["sa_id"])}`
        );
      } else if (value === "Delete") {
        setIsDelete(true);
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Allotment No " + row.tm_number,
          model: model,
          redirect: "/allotmentList",
          Breadcrumb: "Allotment List",
        };
        dispatch(historyData(historyDetails));
        navigate(
          `/module-history?status=${encryptDataForURL(
            value
          )}&id=${encryptDataForURL(row["sa_id"])}`
        );

        setPopupIndex(-1);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (moduleType === "sampleverification") {
      if (value === "View") {
        navigate(
          `/verificationList/sampleVerification?view=${encryptDataForURL(
            "view"
          )}&status=${encryptDataForURL(
            "verification"
          )}&sampleVarificationId=${encryptDataForURL(row["sv_id"])}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Verification No " + row.sv_verificationno,
          model: model,
          redirect: "/verificationList",
          Breadcrumb: "Verification List",
        };
        dispatch(historyData(historyDetails));
        navigate(
          `/module-history?status=${encryptDataForURL(
            value
          )}&id=${encryptDataForURL(row["sv_id"])}`
        );

        setPopupIndex(-1);
      } else if (value === "Delete") {
        setIsDelete(true);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (moduleType === "sfm") {
      if (value === "View") {
        navigate(
          `/SFMList/result?view=${encryptDataForURL(
            "view"
          )}&status=${encryptDataForURL("SFMResult")}&sfmid=${encryptDataForURL(
            row["sfm_id"]
          )}`
        );
      } else if (value === "PDF") {
        const historyDetails = {
          recordNo: "SFM No " + row.sfm_msfm_no,
          model: model,
          redirect: "/SFMList",
          Breadcrumb: "SFM List",
        };
        dispatch(historyData(historyDetails));
        const encryptedValue = encryptDataForURL(value);
        const encryptedId = encryptDataForURL(row["sfm_id"]);
        navigate(`/SFMList/SFMPDF?status=${encryptedValue}&id=${encryptedId}`);

        setPopupIndex(-1);
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "SFM No " + row.sfm_msfm_no,
          model: model,
          redirect: "/SFMList",
          Breadcrumb: "SFM List",
        };
        dispatch(historyData(historyDetails));
        navigate(
          `/module-history?status=${encryptDataForURL(
            value
          )}&id=${encryptDataForURL(row["sfm_id"])}`
        );

        setPopupIndex(-1);
      } else if (value === "PDF") {
        const historyDetails = {
          recordNo: "SFM No " + row.sfm_msfm_no,
          model: model,
          redirect: "/SFMList",
          Breadcrumb: "SFM List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/SFMPDF?status=${value}&id=${row["sfm_id"]}`);

        setPopupIndex(-1);
      } else if (value === "Delete") {
        setIsDelete(true);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (moduleType === "internalcertificate") {
      if (value === "View") {
        navigate(
          `/testmemoList/test-results?view=${encryptDataForURL(
            "view"
          )}&status=${encryptDataForURL(
            "testmemoresult"
          )}&editId=${encryptDataForURL(row["ic_id"])}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "ULR No " + row.ic_ulrno,
          model: model,
          redirect: "/testReport",
          Breadcrumb: "Test Report List",
        };
        dispatch(historyData(historyDetails));
        navigate(
          `/module-history?status=${encryptDataForURL(
            value
          )}&id=${encryptDataForURL(row["ic_id"])}`
        );

        setPopupIndex(-1);
      } else if (value === "Edit") {
        navigate(
          `/testmemoList/test-results?status=${encryptDataForURL(
            "testmemoresult"
          )}&editId=${encryptDataForURL(row["ic_id"])}`
        );
      } else if (value === "Delete") {
        setIsDelete(true);
      } else if (value === "Preview Report") {
        navigate(`/testReport/preview/${encryptDataForURL(row["ic_id"])}`);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (moduleType === "jrf") {
      if (value === "Delete") {
        setIsDelete(true);
      } else if (value === "Download") {
        navigate(`/jrfListing/jrf-pdf-preview/${encryptDataForURL(id)}`);
        setPopupIndex(-1);
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "JRF No " + row.jrf_no,
          model: model,
          redirect: "/jrfListing",
          Breadcrumb: "JRF List",
        };

        dispatch(historyData(historyDetails));
        navigate(
          `/module-history?status=${encryptDataForURL(
            value
          )}&id=${encryptDataForURL(id)}`
        );

        setPopupIndex(-1);
      }
      // else if (value === "Documents") {
      //   console.log("Documents", row);
      //   const historyDetails = {
      //     recordNo: "JRF No " + row.jrf_no,
      //     model: model,
      //     redirect: "/jrfListing",
      //     Breadcrumb: "JRF List",
      //   };

      //   dispatch(historyData(historyDetails));
      //   navigate(
      //     `/module-document?module=${formConfig?.listView?.module}&id=${id}`
      //   );
      // } 
      else {
        if (row.jrf_is_external) {
          navigate(
            `/jrfListing/external-jrf?status=${encryptDataForURL(
              value
            )}&id=${encryptDataForURL(id)}`
          );
        } else if (row.jrf_is_ops) {
          navigate(
            `/jrfListing/operation-jrf?status=${encryptDataForURL(
              value
            )}&id=${encryptDataForURL(id)}`
          );
        } else {
          navigate(
            `/jrfListing/jrf?status=${encryptDataForURL(
              value
            )}&id=${encryptDataForURL(id)}`
          );
        }
        setPopupIndex(-1);
      }
    } else if (
      moduleType === "jobinstruction" ||
      moduleType === "jioperationjsonb"
    ) {
      if (value === "Delete") {
        setIsDelete(true);
      }
      // else if (value === "Documents") {
      //   const historyDetails = {
      //     recordNo: "JRF No " + row.jrf_no,
      //     model: model,
      //     redirect: "/operation/jrfInstructionListing",
      //     Breadcrumb: "JRF Instruction",
      //   };

      //   dispatch(historyData(historyDetails));
      //   navigate(
      //     `/module-document?module=${encryptDataForURL(
      //       moduleType
      //     )}&id=${encryptDataForURL(row["ji_id"])}`
      //   );
      // }
      else if (value === "History") {
        const historyDetails = {
          recordNo: "Job Instruction No " + row.ji_reference_number,
          model: model,
          redirect: "/operation/jrfInstructionListing",
          Breadcrumb: "Job Instruction List",
        };

        dispatch(historyData(historyDetails));
        navigate(
          `/module-history?status=${encryptDataForURL(
            value
          )}&id=${encryptDataForURL(row["ji_id"])}`
        );

        setPopupIndex(-1);
      } else if (value === "Documents") {
        navigate(
          "/operation/JI-commercial-certificate-list/" +
          encryptDataForURL(row.ji_id)
        );
      } else if (value === "Edit") {
        navigate(
          `/operation/jrfInstructionListing/job-instruction/${encryptDataForURL(
            row["ji_id"]
          )}`
        );
        setPopupIndex(-1);
      } else if (value === "Man Power") {
        navigate(
          `/operation/jrfInstructionListing/job-instruction/man-power/${encryptDataForURL(
            row["ji_id"]
          )}`
        );
      } else {
        navigate(
          `/operation/vessel-ji-list/${encryptDataForURL(
            row["ji_id"]
          )}?action=${encryptDataForURL("View")}&useFor=${encryptDataForURL(
            "viewOnly"
          )}`
        );
        setPopupIndex(-1);
      }
    } else if (moduleType === "vesselOperation") {

      if (value === "Delete") {
        setIsDelete(true);
      }
      else {
        if (from === "subListTable") {
          if (subModuleType === "commercialCertificate") {
            if (
              ![
                "Transportable moisture limit (TML)",
                "Quality Analysis",
                "Pre-Shipment (PSI)",
              ].includes(row?.activity)
            ) {
              let payload = {
                ji_id: row?.fk_jiid,
                jis_id: row?.fk_jisid,
                tenant: 1,
              };
              // let OPSDSRes = await postDataFromApi(
              //   "/ops-vessel-ds/get/",
              //   payload
              // );
              let OPSDSRes = await getNonLMSDetailsById(row.activity, payload);
              if (OPSDSRes.status === 200) {
                navigate(
                  `/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/${encryptDataForURL(
                    OPSDSRes?.data?.data?.opsvd_id
                  )}/${encryptDataForURL(row.cc_id)}?status=${encryptDataForURL(
                    "View"
                  )}&CCID=${encryptDataForURL(row.cc_id)}&OperationType=${encryptDataForURL(row.activity)}`
                );
              }
            } else {
              navigate(
                `/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/${encryptDataForURL(
                  row?.fk_jiid
                )}/${encryptDataForURL(row.cc_id)}?status=${encryptDataForURL(
                  "View"
                )}&CCID=${encryptDataForURL(row.cc_id)}&OperationType=${encryptDataForURL(row.activity)}`
              );
            }
          } else {
            if (
              [
                "Transportable moisture limit (TML)",
                "Quality Analysis",
                "Pre-Shipment (PSI)",
              ].includes(row?.activity)
            ) {

              navigate(
                "/operation/vessel-ji-list/vessel-list/" +
                encryptDataForURL(row["fk_jiid"]) +
                "/" +
                encryptDataForURL(row["activity_master"]["activity_name"]) +
                "?OperationType=" +
                encryptDataForURL(row["activity_master"]["activity_name"]) +
                "&operationId=" +
                encryptDataForURL(row["jis_id"]) +
                "&operationStepNo=" +
                encryptDataForURL(1) +
                "&action=" +
                encryptDataForURL("opsView")
              );
            } else if (moduleType === "vesselOperation" && formConfig?.listView?.subModuleType === 'configurationList') {
              navigate(
                "/operation/vessel-ji-list/vessel-list/" +
                encryptDataForURL(row["fk_jiid"]) +
                "/" +
                encryptDataForURL(OperationType) +
                "?OperationType=" +
                encryptDataForURL(OperationType) +
                "&operationId=" +
                encryptDataForURL(row["jis_id"]) +
                "&useFor=" +
                encryptDataForURL("viewOnly") +
                "&action=" +
                encryptDataForURL("View")
              );
            } else {
              navigate(
                "/operation/vessel-ji-list/vessel-list/" +
                encryptDataForURL(row["fk_jiid"]) +
                "/" +
                encryptDataForURL(row["activity_master"]["activity_name"]) +
                "?OperationType=" +
                encryptDataForURL(row["activity_master"]["activity_name"]) +
                "&operationId=" +
                encryptDataForURL(row["jis_id"]) +
                "&useFor=" +
                encryptDataForURL("viewOnly") +
                "&action=" +
                encryptDataForURL("View")
              );
            }
          }
          setPopupIndex(-1);
        }
      }
    } else if (moduleType === "JICommercialCertificateList") {
      if (value === "Delete") {
        setIsDelete(true);
      }else{
        setPopupType(value);
      }
    }
  };

  const handleDeleteData = async () => {
    if (moduleType === "sampleinward") {
      handleSimpleInwardDeleteData();
      return;
    } else if (moduleType === "testmemomain") {
      handleTestMemoDeleteData();
      return;
    } else if (moduleType === "allotment") {
      handleAllotmentDelete();
      return;
    } else if (moduleType === "sampleverification") {
      handleSampleVerificationDelete();
      return;
    } else if (moduleType === "sfm") {
      handleSFMDelete();
      return;
    } else if (moduleType === "internalcertificate") {
      handleinternalcertificateDelete();
      return;
    } else if (moduleType === "jobinstruction") {
      handleJobInstructionDelete(
        row.ji_id,
        setIsDelete,
        getAllListingData,
        setPopupIndex
      );
      return;
    }
    else if (moduleType === "vesselOperation") {
      handleVesselOperationDelete(
        row,
        setIsDelete,
        getAllListingData,
        setPopupIndex
      );
      return;
    }
    else if (moduleType === "JICommercialCertificateList") {
      handleDocumentDelete(
        row,
        setIsDelete,
        getAllListingData,
        setPopupIndex
      );
      return;
    }
    else {
      let deleteBody = {
        jrf_id: id,
      };
      let res = await deleteDataFromApi(JRFDeleteApi, deleteBody);
      if (res?.data?.status === 200) {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setIsDelete(false);
      setPopupIndex(-1);
      getAllListingData();
    }
  };
  const handleSimpleInwardDeleteData = async () => {
    let res;
    if (row["status"] == "rejected") {
      let MainData = {
        jrf_id: id,
        jrf_data: {
          jrf_status: "cancelled",
        },
      };
      res = await putDataFromApi(formConfig.apiEndpoints.update, MainData);
    } else {
      let deleteBody = {
        smpl_inwrd_id: sampleInwardFormId,
      };
      res = await deleteDataFromApi(sampleInwardDetailsDeleteAPI, deleteBody);
    }
    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleTestMemoDeleteData = async () => {
    let deleteBody = {
      test_memo_id: row.tm_id,
    };
    let res = await deleteDataFromApi(testMemoDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleAllotmentDelete = async () => {
    let deleteBody = {
      sa_id: row.sa_id,
    };
    let res = await deleteDataFromApi(allotmentDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleSampleVerificationDelete = async () => {
    let deleteBody = {
      sv_id: row.sv_id,
    };
    let res = await deleteDataFromApi(sampleverificationDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleSFMDelete = async () => {
    let deleteBody = {
      sfm_id: row.sfm_id,
    };
    let res = await deleteDataFromApi(SFMDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleinternalcertificateDelete = async () => {
    let deleteBody = {
      ic_id: row.ic_id,
    };
    let res = await deleteDataFromApi(InternalCertificateDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleDownloadJRF = async () => {
    let bodyData = {
      jrf_id: id,
    };
    let res = await postDataFromApi(JRFPDFDownloadApi, bodyData, "", 1);
    if (res?.status === 200) {
      let pdfDate = "JRF" + id + "_" + getFormatedDate(new Date(), "", 1);
      const blob = new Blob([res.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = pdfDate + ".pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setIsDelete(false);
    setPopupIndex(-1);
  };
  const openDeletePopup = () => {
    let headingMsg = "Confirmation!";
    let titleMsg = "";
    if (actionType == "Delete") {
      headingMsg = formConfig?.popupMessages?.delete?.headerMsg;
      titleMsg = formConfig?.popupMessages?.delete?.titleMsg;
    } else if (actionType == "Download") {
      headingMsg = formConfig?.popupMessages?.download?.headerMsg;
      titleMsg = formConfig?.popupMessages?.download?.titleMsg;
    }
    return (
      <DeleteConfirmation
        isOpen={isDelete}
        handleClose={setIsDelete}
        handleConfirm={() =>
          actionType == "Download" ? handleDownloadJRF() : handleDeleteData()
        }
        popupMessage={titleMsg}
        popupHeading={headingMsg}
      />
    );
  };
  const chkActionVisibility = (
    rolePermissions,
    module,
    permission,
    value = ""
  ) => {
    let isVisbile = false;
    if (
      permission &&
      isModuelePermission(rolePermissions, module, permission)
    ) {
      if (module == "jrf") {
        if (value.toLowerCase() === "edit") {
          let data = {
            BU: ["saved", "rejected"],
            LR: ["saved"],
          };
          if (data[user?.role].includes(row.jrf_status)) {
            isVisbile = true;
          }
        } else if (value.toLowerCase() === "delete") {
          let data = {
            BU: ["saved", "rejected"],
            LR: [],
          };
          if (data[user?.role].includes(row.jrf_status)) {
            isVisbile = true;
          }
        } else {
          isVisbile = true;
        }
      } else if (module == "sampleinward") {
        if (value.toLowerCase() === "edit") {
          let data = {
            LR: ["created", "saved"],
          };
          if (data[user?.role].includes(row.smpl_status)) {
            isVisbile = true;
          }
        } else {
          isVisbile = true;
        }
      } else if (module == "internalcertificate") {
        if (value.toLowerCase() === "edit") {
          let data = {
            LR: ["rejected", "saved"],
            TM: [],
            STM: [],
            QM: [],
            SQM: [],
            SLC: [],
            DTM: [],
            LC: [],
          };
          if (data[user?.role].includes(row.status)) {
            isVisbile = true;
          }
        } else {
          isVisbile = true;
        }
      } else if (module == "jobinstruction") {
        if (value.toLowerCase() === "edit") {
          let data = {
            BU: [],
            "OPS ADMIN": ["saved", "created", "pre-analysis", "analysis"],
          };
          if (data[user?.role].includes(status)) {
            isVisbile = true;
          }
        } else if (value.toLowerCase() === "delete") {
          let data = {
            BU: [],
            "OPS ADMIN": ["saved"],
          };
          if (data[user?.role].includes(status)) {
            isVisbile = true;
          }
        } else {
          isVisbile = true;
        }
      }
      else {
        isVisbile = true;
      }
    } else {
      if (value.toLowerCase() === "download") {
        let data = {
          BU: ["posted", "saved"],
          LR: ["posted", "saved"],
        };
        if (!data[user?.role].includes(row.jrf_status)) {
          isVisbile = true;
        }
      }
      else if (
        value.toLowerCase() === "pdf" &&
        (formConfig?.listView?.moduleType === "sfm" ||
          formConfig?.listView?.moduleType === "testmemomain")
      ) {
        let data = {
          TM: ["certified"],
          STM: ["certified"],
          QM: ["certified"],
          SQM: ["certified"],
          SLC: ["certified","completed"],
          LR: ["certified"],
          DTM: ["certified"],
          LC: ["completed"],
        };
        if (data[user?.role].includes(row.status || row.sfm_status)) {
          isVisbile = true;
        }
      } else if (value.toLowerCase() === "history") {
        isVisbile = true;
      } else if (
        value.toLowerCase() === "pdf" &&
        (moduleType === "sfm" || moduleType === "testmemomain")
      ) {
        let data = {
          TM: ["certified"],
          LR: ["certified"],
          DTM: ["certified"],
          LC: ["completed"],
        };

        if (
          data[user?.role].includes(row?.status) ||
          data[user?.role].includes(row?.sfm_status)
        ) {
          isVisbile = true;
        }
      } else if (value.toLowerCase() === "documents") {
        isVisbile = true;
      } else if (value.toLowerCase() === "man power") {
        isVisbile = true;
      } else if (value.toLowerCase() === "commercial certificate") {
        isVisbile = true;
      }


    }
    return isVisbile;
  };
  const chkSubListVisibility = (from, moduleType, value) => {
    if (value?.toLowerCase() === "delete" && row?.status === "completed") {
      return false
    }
    else {
      if (from === "subListTable") {
        return true
      }
    }

  }
  return (
    <div className={"popupOptions" + (isBottom ? " IsBottom" : "")}>
      {actions.map((action, actionIndex) => {
        return (action.value &&
          action.value !== "" &&
          action.type === "icon" &&
          action.status !== "assignmentview" &&
          chkActionVisibility(
            rolePermissions,
            section.moduleType,
            action.permission,
            action.value
          )) ||
          chkSubListVisibility(
            from,
            section.moduleType,
            action.value
          )
          ? (
            <div key={"sample-inward-" + actionIndex}>
              <button
                type="button"
                className="nonNativeButton2"
                aria-label="Sample Inward Action Button"
                onClick={() =>
                  moduleType === "sampleinward"
                    ? handleClick(action.status, action.value)
                    : handleClick(action.value)
                }
              >
                <i className={action.label}></i>
                {action.value}
              </button>
            </div>
          ) : action.status === "assignmentview" &&
            row["smpl_status"] === "assigned" ? (
            <div key={"actionIndex-" + actionIndex}>
              <button
                type="button"
                className="nonNativeButton2"
                aria-label="Assignment View Button"
                onClick={() =>
                  moduleType === "sampleinward"
                    ? handleClick(action.status, action.value)
                    : handleClick(action.value)
                }
              >
                <i className={action.label}></i>
                {action.value}
              </button>
            </div>
          ) : action.status === "JICommercialList" ? (
            <div key={"actionIndex-" + actionIndex}>
              <button
                type="button"
                className="nonNativeButton2"
                aria-label="Assignment View Button"
                onClick={() => handleClick(action.value)}
              >
                <i className={action.label}></i>
                {action.value}
              </button>
            </div>
          ) : null;
      })}
      {isDelete && openDeletePopup()}
    </div>
  );
};
PopupOptions.propTypes = {
  popupActions: PropTypes.arrayOf(PropTypes.object),
  setPopupIndex: PropTypes.func,
  id: PropTypes.string,
  row: PropTypes.object,
  section: PropTypes.string,
  getAllListingData: PropTypes.func,
  formConfig: PropTypes.object,
  type: PropTypes.string,
  sampleInwardFormId: PropTypes.string,
  model: PropTypes.object,
  isBottom: PropTypes.bool,
  status: PropTypes.string,
  setDontClick: PropTypes.func,
};

PopupOptions.propTypes = {
  popupActions: PropTypes.arrayOf(PropTypes.object), // Array of actions, each action being an object
  setPopupIndex: PropTypes.func, // Function to set the popup index
  id: PropTypes.string, // Unique identifier for the popup
  row: PropTypes.number, // Row number, if applicable
  section: PropTypes.string, // Section name or identifier
  getAllListingData: PropTypes.func, // Function to retrieve all listing data
  formConfig: PropTypes.object, // Configuration object for the form
  type: PropTypes.string, // Type of the popup or action
  sampleInwardFormId: PropTypes.string, // Identifier for the sample inward form
  model: PropTypes.any, // Model object; use any if the type is flexible
  isBottom: PropTypes.bool, // Boolean indicating if the popup is positioned at the bottom
  status: PropTypes.string, // Status string, e.g., 'active', 'inactive'
  setDontClick: PropTypes.func, // Function to control click behavior
};

export default PopupOptions;
