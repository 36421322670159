import { useTranslation } from "react-i18next";
import React from "react";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
const OperationCertificateButtons = ({
  status,
  moduleSubType,
  RPCID,
  encryptDataForURL,
  EditRecordId,
  JISID,
  previewCertificate,
  generateCertificate,
  isValideValue,
  handleShareFile,
  EditGeneratedCertificate,
}) => {
  const { t } = useTranslation();
  const translate = t;
  let navigate = useNavigate();

  return (
    <div className="submit_btns">
      {status !== "Edit" && moduleSubType !== "ShareFiles" && (
        <button
          onClick={() => {
            navigate(
              `/operation/vessel-ji-list/vessel-list/confirugation-certificate-list/${encryptDataForURL(EditRecordId)}/${encryptDataForURL(JISID)}`
            )
          }

          }
          className="cancelBtn"
          type="button"
        >
          Back
        </button>
      )}
      {status === "approve" && (
        <button
          className="submitBtn"
          type="button"
          onClick={() => previewCertificate(RPCID)}
        >
          Preview
        </button>
      )}

      {status !== "approve" &&
        status !== "Edit" &&
        moduleSubType !== "ShareFiles" && (
          <>
            <button
              className="submitBtn"
              type="button"
              onClick={() => generateCertificate(status, "saved")}
            // disabled={!isValideValue}
            >
              Save
            </button>
            <button
              className="submitBtn"
              type="button"
              onClick={() => generateCertificate(status, "posted")}
            // disabled={!isValideValue}
            >
              Generate
            </button>
          </>
        )}
      {moduleSubType === "ShareFiles" && (
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="cancelBtn"
          type="button"
        >
          Back
        </button>
      )}
      {moduleSubType === "ShareFiles" && (
        <button
          className="submitBtn"
          type="button"
          onClick={() => handleShareFile()}
        // onClick={() => generateCertificate()}
        >
          Share File
        </button>
      )}

      {status === "Edit" && (
        <>
          <button
            className="submitBtn"
            type="button"
            onClick={() => EditGeneratedCertificate("saved")}
          >
            Save
          </button>
          <button
            className="submitBtn"
            type="button"
            onClick={() => EditGeneratedCertificate("posted")}
          // disabled={isValideValue}
          >
            Generate
          </button>
        </>
      )}
    </div>
  );
};


OperationCertificateButtons.propTypes = {
  setIsPopupOpen: PropTypes.func,
  setJRFCreationType: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleBackButtonFunction: PropTypes.func,
  viewOnly: PropTypes.bool,
};

export default OperationCertificateButtons;
