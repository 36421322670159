/*
Use : Use for check permission available for perticular functionaliy
Author : sufiyan Patel
Date:28-04-2024
*/
import moment from "moment";
import {
  decryptDataForURL,
  encryptData,
  encryptDataForURL,
} from "../utills/useCryptoUtils";
import { use } from "i18next";
import { store } from "./store";
import { GetTenantDetails } from "./commonServices";
export const rolesDetails = [
  { role: "BU", label: "Operation Executive" },
  { role: "LR", label: "Lab Receptionist" },
  { role: "TM", label: "Technical Manager" },
  { role: "DTM", label: "Deputy Technical Manager" },
  { role: "LC", label: "Chemist" },
  { role: "OPS ADMIN", label: "Admin" },
  { role: "STM", label: "Senior Technical Manager" },
  { role: "QM", label: "Quality Manager" },
  { role: "SQM", label: "Senior Technical Manager" },
  { role: "SLC", label: "Senior Chemist" },
];
export const CommonTMRoles = ["TM", "STM", "QM", "SQM", "SLC"];
/*
Use : Use for check permission available for module and showing in sidebar
Author : sufiyan Patel
Date:28-04-2024
*/

export const formatDate = (dateString) => {
  if (!dateString) return "";
  const [datePart] = dateString.split("T");
  const [year, month, day] = datePart.split("-");
  return `${day}/${month}/${year}`;
};

export const isModuelePermission = (
  allPermissions,
  module,
  permission,
  isMainPrmission,
  iscustom = ""
) => {
  if (iscustom) {
    return true;
  }
  const state = store.getState();
  const userData = state.session.user;
  if (isMainPrmission) {
    if (module === "operations") {
      if (["BU", "OPS ADMIN", "BH"].includes(userData?.role)) {
        return true;
      } else {
        return false;
      }
    }
    if (module === "LMS") {
      if (!["OPS ADMIN", "BH"].includes(userData?.role)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
  // return true
  if (iscustom) {
    return true;
  }
  if (GetTenantDetails(1, 1) === "TPBPL" && module === "sampleverification") {
    return false;
  }
  module = permission + "_" + module;
  if (allPermissions) {
    let allPermissionsArrray = allPermissions;
    let isValideData = allPermissionsArrray.filter((per, i) => {
      var spPer = per.split(".");
      let spValue = "";
      if (spPer.length > 1) {
        spValue = spPer[1];
      } else {
        spValue = spPer[0];
      }
      return spValue === module;
    });
    if (isValideData.length > 0) {
      return true;
    }
  }

  return false;
};

/*
Use : Use for Check Validation of password field
Author : sufiyan Patel
Date:08-04-2024
*/
export const checkPasswordValidation = (password = "") => {
  const passAuth = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,15}$/
  );
  return password.match(passAuth) !== null ? true : false;
};
/*
Use : use for get proper date format
Author : sufiyan Patel
Date:08-04-2024
*/
export const getFormatedDate = (date, isListing, idDownLoad = "") => {
  if (!date) {
    return "--";
  }
  let actualDate = new Date(date);
  if (isListing || idDownLoad) {
    return moment(actualDate).format("DD/MM/YYYY");
  } else {
    return moment(actualDate).format("MMMM DD,YY kk:mm");
  }
};
export const getFormatedDateWithtime = (date, isListing) => {
  if (!date) {
    return "--";
  }
  let actualDate = new Date(date);
  if (isListing) {
    return moment(actualDate).format("DD/MM/YYYY H:mm:ss");
  } else {
    return moment(actualDate).format("MMMM DD,YY kk:mm");
  }
};
export const verifyPassword = (password) => {
  const result = [];
  result.push(password.length >= 8 && password.length <= 15);
  result.push(/[A-Z]/.test(password));
  result.push(/[a-z]/.test(password));
  result.push(/\d/.test(password));
  result.push(/[@$!%*?&]/.test(password));
  return result;
};

export const getPasswordVerificationHint = (password) => {
  const passwordVerificationResult = verifyPassword(password);
  return (
    <ul className="passwordPlolicyhint">
      <p style={{ color: "green", fontSize: "0.875rem", lineHeight: 1 }}>
        {passwordVerificationResult.map((verification, index) => (
          <li
            className="errorMsg"
            key={"index" + index}
            style={{ color: verification ? "green" : "$danger" }}
          >
            {verification ? "✓" : "✗"}&nbsp;
            {index === 0
              ? "Password should be minimum 8 and maximum 15 characters"
              : ""}
            {index === 1 ? "At least one uppercase letter" : ""}
            {index === 2 ? "One lowercase letter" : ""}
            {index === 3 ? "One number" : ""}
            {index === 4 ? "One special character" : ""}
          </li>
        ))}
      </p>
    </ul>
  );
  return "";
};
export const redirectPageAfterLogin = (navigate, role, isReload) => {
  const jrfRoles = ["LR"];
  const allotRoles = ["TM", "STM", "QM", "SQM", "SLC", "DTM"];
  const verificationRoles = ["LC"];
  const operations = ["OPS ADMIN", "BU"];
  if (role !== undefined) {
    if (jrfRoles.includes(role)) {
      navigate("/jrfListing");
    } else if (allotRoles.includes(role)) {
      navigate("/testmemoList");
    } else if (verificationRoles.includes(role)) {
      navigate("/allotmentList");
    } else if (operations.includes(role)) {
      navigate("/operation/jrfInstructionListing");
    } else if (role === "BH") {
      navigate("/operation/commercial-certificate-list");
    } else {
      navigate("/jrfListing");
    }
    if (isReload) {
      setTimeout(() => {
        window.location.reload();
      }, 10);
    }
  }
};

export const getSelectedOptionName = (
  options = [],
  masterOptions = [],
  name = "",
  value,
  customname = "",
  isSingleValue,
  isArray,
  isCustomOption,
  customOptions
) => {
  console.log(name, "options", value, options);
  let allOptions = [];
  let newOptions = [];
  let selectedOptions = [];
  if (isSingleValue) {
    if (typeof value === "string") {
      selectedOptions.push(value);
    } else {
      selectedOptions.push(parseInt(value));
    }
  } else {
    selectedOptions = value;
  }
  if (!selectedOptions || !selectedOptions.length) {
    return [];
  }
  if (isCustomOption) {
    newOptions = customOptions;
  } else {
    masterOptions?.map((model, index) => {
      if (model.model === name || model.model === customname) {
        newOptions = model.data;
      }
    });
  }

  if (newOptions.length > 0) {
    newOptions.forEach((option) => {
      if (
        selectedOptions.includes(option.id) ||
        selectedOptions.includes(option.name) ||
        selectedOptions.includes(option.id.toString())
      ) {
        allOptions.push(option.name);
      }
    });
  } else {
    allOptions = options.filter((option) => {
      if (selectedOptions.includes(option)) {
        return option;
      }
      return false;
    });
  }

  if (isArray) {
    if (allOptions.length > 0) {
      return allOptions;
    } else {
      return selectedOptions;
    }
  }
  if (allOptions.length > 0) {
    return allOptions.join(", ");
  } else {
    return selectedOptions.join(", ");
  }
};
export const getComonCodeForCompany = (code) => {
  const companyCodes = {
    L: "TIPL",
    C: "TCRC",
    P: "TPBPL",
  };
  return companyCodes[code];
};
export const getTotalValues = (formData, type, header) => {
  let count = 0;
  header.map((field) => {
    if (formData[1][field.name + "-" + type]) {
      count =
        parseFloat(count) + parseFloat(formData[1][field.name + "-" + type]);
    }
  });
  return count;
};
export const getDisplacementDifferenceCalc = (formData) => {
  let value1 = formData[1]?.["corrected_displacement-initial"] || 0;
  let value2 = formData[1]?.["corrected_displacement-interim"] || 0;
  let value3 = formData[1]?.["corrected_displacement-final"] || 0;
  let finalValue =
    parseFloat(value3) - (parseFloat(value1) + parseFloat(value2));
  return finalValue;
};
export const getChangeOnShipsValue = (formData, initialTotal, finalTotal) => {
  let finalValue = 0;
  if (formData[0].ji_is_loading==="Loading") {
    finalValue = parseFloat(initialTotal) - parseFloat(finalTotal);
  } else {
    finalValue = parseFloat(finalTotal) - parseFloat(initialTotal);
  }
  return finalValue;
};

export const shortArray = (array, fieldName, type = "asc") => {
  let sortArr = [];
  if (type === "asc") {
    sortArr = array.sort((a, b) => a[fieldName] - b[fieldName]);
  } else {
    sortArr = array.sort((a, b) => b[fieldName] - a[fieldName]);
  }
  return sortArr;
};

export const getDayCountFromDate = (fromDate, toDate, isColor = "") => {
  let diffDays = 0;
  if (fromDate || toDate) {
    const endDate = toDate ? moment(toDate) : moment();
    const startDate = fromDate ? moment(fromDate) : moment();
    if (endDate.isSame(startDate, "date")) {
    }
    diffDays = endDate.diff(startDate, "days");
    diffDays = diffDays + 1;
  }
  diffDays = diffDays > 0 ? diffDays : 0;
  return isColor ? getDaysColorCount(diffDays) : diffDays;
};
const getDaysColorCount = (count) => {
  let color = "#e11d07";
  if (count >= 5 && count <= 10) {
    color = "#ffbc34";
  } else if (count > 10) {
    color = "#53ff34";
  }
  return (
    <span style={{ color: color }}>
      {count + (count > 1 ? " Days" : " Day")}
    </span>
  );
};

// export const getCellData = (cellData) => {
//   if (!cellData) {
//     return "--";
//   }
//   const breakingLimit = 18
//   if (cellData.length > breakingLimit) {
//     const firstLine = cellData.substring(0, breakingLimit); // Get the first 20 characters
//     let secondLine
//     if (cellData.length < 2 * breakingLimit) {
//       secondLine = cellData.substring(breakingLimit, breakingLimit * 2); // Get the rest of the characters
//     }
//     else {
//       secondLine = cellData.substring(breakingLimit, breakingLimit * 2 - 3) + "..."; // Get the rest of the characters
//     }
//     return <span>{firstLine}<br />{secondLine}</span>; // Return as two lines
//   }

//   return cellData;
// };

export const getCellData = (cellData) => {
  if (!cellData) {
    return "--";
  }

  const breakingLimit = 20; // Maximum character limit per line
  if (cellData.length > breakingLimit) {
    // Find the last space within the first breakingLimit characters to avoid cutting words
    let firstLine = cellData.substring(0, breakingLimit);
    const lastSpaceIndex = firstLine.lastIndexOf(" ");

    // Adjust firstLine to end at the last space, if a space exists
    if (lastSpaceIndex !== -1) {
      firstLine = cellData.substring(0, lastSpaceIndex);
    }

    // The remaining text starts after the firstLine
    let secondLine = cellData.substring(firstLine.length).trim();

    // If the second line is too long, trim it and add ellipsis
    if (secondLine.length > breakingLimit) {
      secondLine = secondLine.substring(0, breakingLimit - 3).trim() + "...";
    }

    return (
      <span>
        {firstLine}
        <br />
        {secondLine}
      </span>
    );
  }

  return cellData;
};

export const numberToOrdinalWord = (num) => {
  const ordinals = [
    "Zeroth",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
    "Eleventh",
    "Twelfth",
    "Thirteenth",
    "Fourteenth",
    "Fifteenth",
    "Sixteenth",
    "Seventeenth",
    "Eighteenth",
    "Nineteenth",
    "Twentieth",
  ];
  if (num >= 0 && num <= 20) {
    return ordinals[num];
  } else {
    // For numbers > 20, we use a suffix approach.
    let suffix = ["th", "st", "nd", "rd"];
    let value = num % 100;
    let suffixWord = suffix[(value - 20) % 10] || suffix[value] || suffix[0];
    return num + suffixWord;
  }
};
