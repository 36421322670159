import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Context,
  Heading,
  List,
  Plugin,
  ButtonView,
  ContextWatchdog,
  Code,
  Strikethrough,
  Subscript,
  Superscript,
  Underline

} from 'ckeditor5';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import 'ckeditor5/ckeditor5.css';

const InputTextEditor = ({ field }) => {
  const {
    name,
    label,
    value,
    onChange,
    required,
    error,
    placeholder,
    readOnly,
    tooltip,
    validation,
    icon,
    labelWidth,
    fieldWidth,
    pattern,
    characterLimit,
    actionClicked,
    viewOnly,
  } = field;

  const [errorMsg, setErrorMsg] = useState(false);
  console.log(characterLimit);
  useEffect(() => {
    const regex = new RegExp(pattern);
    if (value !== "") {
      if (regex.test(value)) {
        setErrorMsg(false);
      } else {
        setErrorMsg(true);
      }
    } else {
      setErrorMsg(false);
    }
  }, [value]);

  useEffect(() => {
    if ((value === undefined || value === "") && required) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
  }, [value]);
  const editorRef = useRef();
  return (
    <div className="form-group my-2" style={{ position: "relative" }}>
      {label && (
        <label htmlFor={name} style={{ width: labelWidth || `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      <div className={"w-" + (fieldWidth ?? "75") + " d-inline-block mx-2"}>
        <CKEditorContext
          context={Context}
          contextWatchdog={ContextWatchdog}
        // onChangeInitializedEditors={(editors) => {
        //   console.info(editors.editor1?.instance, editors.editor1?.yourAdditionalData);
        // }}
        >
          <CKEditor
            editor={ClassicEditor}
            config={{
              plugins: [
                Essentials,
                Paragraph,
                Heading,
                List,
                Bold,
                Italic,
                Undo,
                Code,
                Strikethrough,
                Subscript,
                Superscript,
                Underline
            ],
            
              // plugins: [
              //   "Essentials",
              //   "Paragraph",
              //   "Heading",
              //   "List",
              //   "Undo",
              //   "Bold",
              //   "Code",
              //   "Italic",
              //   "Strikethrough",
              //   "Subscript",
              //   "Superscript",
              //   "Underline"
              // ],
              // toolbar: ['undo', 'redo', '|', 'heading', 'bold', 'italic', 'numberedList', 'bulletedList'],
              toolbar: {
                items: [
                  'undo', 'redo',
                  '|',
                  'heading',
                  '|',
                  'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                  '|',
                  'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                  '|',
                  'link', 'uploadImage', 'blockQuote', 'codeBlock',
                  '|',
                  'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                ],
                shouldNotGroupWhenFull: false
              }

            }}
            // data=''
            contextItemMetadata={{
              name: 'editor1',
              yourAdditionalData: 2
            }}
            // onReady={(editor) => {
            //   // You can store the "editor" and use when it is needed.
            //   console.log('Editor 1 is ready to use!', editor);
            // }}
            onReady={(editor) => {
              editorRef.current = editor;
            }}
            data={value}
            onChange={() => {
              // onChange( editorRef.current?.getData() );
              // console.log(editorRef.current?.getData());
              onChange(editorRef.current?.getData());
            }}
          />

        </CKEditorContext>
      </div>
    </div>

  );
}

InputTextEditor.propTypes = {
  field: PropTypes.object.isRequired,
};
export default InputTextEditor;