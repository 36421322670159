import React, { useEffect, useState } from "react";
import RenderFields from "../RenderFields";
import { Row } from "react-bootstrap";
import viewJIDetails from "../../../formJsonData/Operations/jobinstructions/viewJIDetails.json";
import RenderTablePreview from "../RenderTableSectionPreview";
import RenderTableSection from "../RenderTableSection";
import { getSingleJiRecordForPreview } from "../commonHandlerFunction/jobinstructionHandlerFunctions";
const JIPopupModal = ({
  section,
  sectionIndex,
  formData,
  handleFieldChange,
  formErrors,
  setViewDetail,
  setIsOverlayLoader,
  isOverlayLoader,
}) => {
  const [subTableData, setSubTableData] = useState([]);
  const [customFormData, setCustomFormData] = useState({});
  const [isShow, setIsShow] = useState(false);
  const getCustomCellValues = (cell) => {
    if (cell.name === "fk_supplierid") {
      if (customFormData[0]?.ji_is_supplier === "Buyer") {
        cell.label = "Buyer";
      } else if (customFormData[0]?.ji_is_supplier === "Supplier") {
        cell.label = "Supplier";
      }
    }
    else if (cell.name === "ji_loading_unloading_name") {
      if (formData[0].ji_is_loading==="Loading") {
        cell.label = "Name of Unloading";
      } else {
        cell.label = "Name of Loading";
      }
    }
    return cell;
  };
  useEffect(() => {
    if (formData[0]?.ji_id) {
      getSingleJiRecordForPreview(
        formData[0]?.ji_id,
        setCustomFormData,
        setIsOverlayLoader,
        viewJIDetails?.scopeWorkData?.scopeWordTableData,
        setIsShow,
        setSubTableData
      );
      // setCustomFormData(formData);
      setTimeout(() => {
        setIsShow(true);
      }, 10);
    }
  }, [formData[0]?.ji_id]);

  return (
    isShow && (
      <div className="popupSearchContainerBG">
        <div className="popupInwardModal  JIPopupModal">
            <h1 className="section_heading_middle">Complete Job Details</h1>
          <div className="rejectSearchCross">
            <button
              onClick={() => setViewDetail(false)}
              className="nonNativeButton2"
              aria-label="Reject Button"
            >
              <i className="bi bi-x-lg h4"></i>
            </button>
          </div>
          <div className="JIPopupModalContainer">
            <Row className="main_form">
              {viewJIDetails?.mainform?.map((field, fieldIndex) => {
                let isShow = true;
                if (
                  field.name == "ji_dispatch_address" &&
                  customFormData[0]?.ji_is_ecertification !== "Print Hard Copy"
                ) {
                  isShow = false;
                } else if (field.name === "ji_with_whom") {
                  if (
                    !customFormData[0]?.ji_type_of_sampling ||
                    ["Independently"].includes(
                      customFormData[0]?.ji_type_of_sampling
                    )
                  ) {
                    isShow = false;
                  }
                }
                else if (field.name === "ji_analysis_with_whom") {
                  if (
                    !formData[0].ji_type_of_analysis ||
                    ["Independently"].includes(
                      formData[0].ji_type_of_analysis
                    )
                  ) {
                    isShow = false;
                  }
                }
                else if (field.name === "fk_consortium_order") {
                  if (customFormData[0]?.ji_is_consortium_order === "No") {
                    isShow = false;
                  }
                } else if (field.name === "ji_dual_port_seq") {
                  if (customFormData[0]?.ji_is_dual_port === "No") {
                    isShow = false;
                  }
                } else if (field.name === "ji_first_ref_no") {
                  if (customFormData[0]?.ji_is_dual_port === "No") {
                    isShow = false;
                  } else if (customFormData[0]?.ji_dual_port_seq === "First") {
                    isShow = false;
                  }
                }
                return (
                  isShow && (
                    <div
                      className={"col-md-" + field.width}
                      key={"Headers-" + fieldIndex}
                    >
                      <RenderFields
                        field={getCustomCellValues(field)}
                        sectionIndex={0}
                        fieldIndex={fieldIndex}
                        formData={customFormData}
                        handleFieldChange={handleFieldChange}
                        formErrors={formErrors}
                        viewOnly={true}
                      />
                    </div>
                  )
                );
              })}
            </Row>
            <Row className="main_form scopeWorkDataPopup">
              {
                <>
                  {viewJIDetails?.scopeWorkData?.mainTitle?.map(
                    (field, fieldIndex) => {
                      let isShow = true;
                      return (
                        isShow && (
                          <div
                            className={"col-md-" + field.width}
                            key={"Headers-" + fieldIndex}
                          >
                            <RenderFields
                              field={getCustomCellValues(field)}
                              sectionIndex={sectionIndex}
                              fieldIndex={fieldIndex}
                              formData={customFormData}
                              handleFieldChange={handleFieldChange}
                              formErrors={formErrors}
                              viewOnly={true}
                            />
                          </div>
                        )
                      );
                    }
                  )}
                  <RenderTableSection
                    key={`${0}-${0}`}
                    section={viewJIDetails?.scopeWorkData?.scopeWordTableData}
                    sectionIndex={1}
                    formData={customFormData}
                    setFormData={setCustomFormData}
                    action={"View"}
                    setTableData={setSubTableData}
                    tableData={subTableData}
                    moduleType={"jobinstruction"}
                    setIsOverlayLoader={setIsOverlayLoader}
                    isOverlayLoader={isOverlayLoader}
                    isUseForViwOnly={true}
                  />
                </>
              }
            </Row>

            <Row className="main_form assignemtn-section-popup">
              {customFormData[1]?.jiqualityanalysis && (
                <>
                  {viewJIDetails?.assignmentTab?.analysisFields?.map(
                    (field, fieldIndex) => {
                      let isShow = true;
                      if (field.name === "ji_with_whom") {
                        if (
                          !customFormData[0]?.ji_type_of_sampling ||
                          ["Independently"].includes(
                            customFormData[0]?.ji_type_of_sampling
                          )
                        ) {
                          isShow = false;
                        }
                      }
                      else if (field.name === "ji_analysis_with_whom") {
                        if (
                          !formData[0].ji_type_of_analysis ||
                          ["Independently"].includes(
                            formData[0].ji_type_of_analysis
                          )
                        ) {
                          isShow = false;
                        }
                      }
                      return (
                        isShow && (
                          <div
                            className={"col-md-" + field.width}
                            key={"Headers-" + fieldIndex}
                          >
                            <RenderFields
                              field={getCustomCellValues(field)}
                              sectionIndex={sectionIndex}
                              fieldIndex={fieldIndex}
                              formData={customFormData}
                              handleFieldChange={handleFieldChange}
                              formErrors={formErrors}
                              viewOnly={true}
                            />
                          </div>
                        )
                      );
                    }
                  )}
                  <Row>
                    <RenderTablePreview
                      key={`${0}-${0}`}
                      section={viewJIDetails?.assignmentTab?.parameterFields}
                      sectionIndex={1}
                      formData={customFormData}
                      isSubPreview={true}
                      moduleType={"jobinstruction"}
                      isSingleParamOnly={true}
                      isUseForViwOnly={true}
                    />
                  </Row>
                </>
              )}
            </Row>
            <Row className="main_form">
              {viewJIDetails?.nominationDetails?.map((field, fieldIndex) => {
                let isShow = true;
                return (
                  isShow && (
                    <div
                      className={"col-md-" + field.width}
                      key={"Headers-" + fieldIndex}
                    >
                      <RenderFields
                        field={getCustomCellValues(field)}
                        sectionIndex={0}
                        fieldIndex={fieldIndex}
                        formData={customFormData}
                        handleFieldChange={handleFieldChange}
                        formErrors={formErrors}
                        viewOnly={true}
                      />
                    </div>
                  )
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    )
  );
};

export default JIPopupModal;
