import React, { useState, useEffect } from "react";
import { postDataFromApi } from "../../../services/commonServices";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import {
  decryptDataForURL,
  encryptDataForURL,
} from "../../../utills/useCryptoUtils";
import {
  getJIsowandactivityApi,
  getReportConfigApi,
  getCommercialCertificateListApi,
  documentUpdate,
  documentShareCreate,
  documentListApi,
  documentCreateApi,
  documentDeleteApi,
  folderCreateApi,
  ccUpdateApi,
  dsSurveyPdfApi,
  ccCertPdfApi,
  mergeFilesApi,
  masterUploadApi,
} from "../../../services/api";
import { downLoadNonLMSCertificatePDFById } from "../commonHandlerFunction/OPscertificate/OPSCertificateHandlerFunctions";

const Preview = ({ pdfUrl, setPdfUrl, sharingPdfUrl, setSharingPdfUrl }) => {
  // const [pdfUrl, setPdfUrl] = useState(null);
  let { EditRecordId, EditSubRecordId } = useParams();
  [EditRecordId, EditSubRecordId] = [EditRecordId, EditSubRecordId].map(
    (item) => (item ? decryptDataForURL(item) : "")
  );
  const hash = window.location.hash;

  const params = new URLSearchParams(hash.split("?")[1]);
  const status = decryptDataForURL(params.get("status"));
  const OperationType = decryptDataForURL(params.get("OperationType"));

  const handleCreateCertificatePrint = async () => {
    let payload, res;
    if (
      //   status !== "NonLMS" &&
      //   status !== "NonLMSApprove" &&
      //   status !== "NonLMSPosted"
      [
        "Transportable moisture limit (TML)",
        "Quality Analysis",
        "Pre-Shipment (PSI)",
      ].includes(OperationType)
    ) {
      payload = {
        ji_id: EditRecordId,
        cc_id: EditSubRecordId,
      };
      res = await postDataFromApi(ccCertPdfApi, payload, "", true, "", "");
    } else if (["Supervision"].includes(OperationType)) {
      res = await downLoadNonLMSCertificatePDFById(
        OperationType,
        EditRecordId,
        EditSubRecordId
      );
    } else {
      //   payload = {
      //     opsvd_id: EditRecordId,
      //   };
      // opsvd_id
      //   res = await postDataFromApi(dsSurveyPdfApi, payload, "", true, "", "");
      // let OPSDSRes = await postDataFromApi(getDSApi, payload);

      res = await downLoadNonLMSCertificatePDFById(
        OperationType,
        EditRecordId,
        EditSubRecordId
      );
    }
    if (res && res.data) {
      // Create a URL for the PDF Blob data
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl); // Set the PDF URL in state to display in the iframe
      console.log("InPreview :", res.data);

      let payload = new FormData();
      payload.append("document", pdfBlob, "certificate.pdf");
      payload.append("model_type ", "commercial_certificate");
      payload.append("bypass_file_size_check ", true);
      payload.append("sub_folder", 6);
      let uploadResponse = await postDataFromApi(
        masterUploadApi,
        payload,
        "TRUE"
      );
      if (uploadResponse.data.status === 200) {
        console.log(uploadResponse.data?.data?.document);
        setSharingPdfUrl(uploadResponse.data?.data?.document);
      }
    }
  };

  // Call the function to fetch the PDF when the component mounts
  useEffect(() => {
    handleCreateCertificatePrint();
  }, []);

  return (
    <div
      style={{ position: "relative" }}
      className={"form-group my-2 previewCommercialCertificate"}
    >
      <h1 className="previewCommercialCertificateHeading">Certificate</h1>
      <div className={"w-100 d-inline-block mx-2"}>
        {pdfUrl ? (
          <iframe
            src={`${pdfUrl}#view=FitH&navpanes=0&toolbar=0&scrollbar=0`} // Hides sidebar, header, toolbar, and scrollbars
            title="Custom PDF Preview"
            name="custom_pdf_iframe" // Custom name for the iframe
            width="100%"
            height="1120px"
            style={{ border: "none", backgroundColor: "white" }} // Set the iframe background to white
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Preview;
