import { toast } from "react-toastify";
import {
  deleteDataFromApi,
  getDataFromApi,
  GetTenantDetails,
  postDataFromApi,
  putDataFromApi,
} from "../../../services/commonServices";
import {
  reportConfigGetApi,
  ccCertGetApi,
  reportHeaderFooterCreateApi,
  reportConfigDeleteApi,
  documentDeleteApi
} from "../../../services/api";
import { encryptDataForURL, decryptDataForURL } from "../../../utills/useCryptoUtils";


export const getReportConfig = async (EditRecordId, EditSubRecordId, configCertStatusRPCID, setFormData) => {
  let payload = {
    ji_id: EditRecordId,
    jis_id: EditSubRecordId,
    rpc_id: configCertStatusRPCID,
  };
  let res = await postDataFromApi(reportConfigGetApi, payload);

  if (res?.data.status === 200) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,

        1: {
          ...prevFormData[1],
          rpc_from_date: res.data.data?.report_configuration.rpc_from_date,
          rpc_to_date: res.data.data?.report_configuration.rpc_to_date,
          rpc_is_lot_no: res.data.data?.report_configuration.rpc_is_lot_no
            ? ["Lot No."]
            : [],
          rpc_is_smpl_qty: res.data.data?.report_configuration.rpc_is_smpl_qty
            ? ["Sample Quantity"]
            : false,
          rpc_is_total_no: res.data.data?.report_configuration.rpc_is_total_no
            ? ["Total Number"]
            : false,
          rpc_is_qty: res.data.data?.report_configuration.rpc_is_qty
            ? ["Quantity"]
            : false,
          rpc_is_smpl_wghtorunit: res.data.data?.report_configuration
            .rpc_is_smpl_wghtorunit
            ? ["Sample Weight / Unit"]
            : false,
          rpc_is_total_qty: res.data.data?.report_configuration
            .rpc_is_total_qty
            ? ["Quantity Total"]
            : false,
          rpc_is_smpl_mark: res.data.data?.report_configuration
            .rpc_is_smpl_mark
            ? ["Sample Mark"]
            : false,
          rpc_is_smpl_type: res.data.data?.report_configuration
            .rpc_is_smpl_type
            ? ["Sample Type"]
            : false,
          rpc_is_smpl_total: res.data.data?.report_configuration
            .rpc_is_smpl_total
            ? ["Sample Total"]
            : false,
          rpc_is_dos: res.data.data?.report_configuration.rpc_is_dos
            ? ["Date of Sampling"]
            : false,
          rpc_is_wght_avg: res.data.data?.report_configuration.rpc_is_wght_avg
            ? ["Weighted Avg."]
            : false,
        },
      };
    });
  }
};

export const getCommercialCertificateSingle = async (EditRecordId, JISID, RPCID, setFormData) => {
  let payload = {
    ji_id: EditRecordId,
    jis_id: JISID,
    cc_id: RPCID,
  };
  let res = await postDataFromApi(ccCertGetApi, payload);
  if (res.data.status === 200) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        0: {
          ...prevFormData[0],
          ...res?.data?.data,
          cert_number: res?.data?.data?.cc_certificatenumber,
          ji_reference_number: res?.data?.data?.cc_refencenumber,
          ji_date: res?.data?.data?.cc_date,
          ji_sub_commodity_name: res?.data?.data?.cc_commodity,
          cc_accountof: res?.data?.data?.cc_accountof,
          jrf_header: res?.data?.data?.cc_topheader,
          jrf_bottom: res?.data?.data?.cc_bottomheader,
        },
      };
    });
  }
};

export const getCommercialCertificateTopBottom = async (setFormData, EditRecordId, OperationType) => {
  console.log("formData : ", OperationType);
  let payload = {
    // "rhf_id": 1,
    "ji_id": EditRecordId,
    // "rhf_type": "ds",
  };

  if (OperationType === "H&H") {
    payload.rhf_type = "hh"
    payload.rhf_id = 3
  } else if (OperationType === "Draft Survey") {
    payload.rhf_type = "ds"
    payload.rhf_id = 4

  } else if (OperationType === "Supervision") {
    payload.rhf_type = "sv"
    payload.rhf_id = 2

  }
  else {
    payload.rhf_type = "cc"
    payload.rhf_id = 1

  }

  let res = await postDataFromApi(reportHeaderFooterCreateApi, payload);
  if (res.data.status === 200) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        0: {
          ...prevFormData[0],
          ...res?.data?.data,
          jrf_header: res?.data?.data?.header,
          jrf_bottom: res?.data?.data?.footer,
        },
      };
    });
  }
};

export const handleVesselOperationDelete = async (
  row,
  setIsDelete,
  getAllListingData,
  setPopupIndex
) => {
  let deleteBody = {
    "rpc_id": row?.rpc_id,
    "ji_id": row?.fk_jiid,
    "jis_id": row?.fk_jisid
  }
  let res = await deleteDataFromApi(reportConfigDeleteApi, deleteBody);

  if (res?.data?.status === 200) {
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsDelete(false);
  setPopupIndex(-1);
  getAllListingData();
};


export const handleDocumentDelete = async (
  row,
  setIsDelete,
  getAllListingData,
  setPopupIndex
) => {
  console.log(row);
  let deleteBody = {
    id: row.dl_id,
  }
  let res = await deleteDataFromApi(documentDeleteApi, deleteBody);

  if (res?.data?.status === 200) {
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsDelete(false);
  setPopupIndex(-1);
  getAllListingData();
};