import React, { useEffect, useState } from 'react';

const RenderVesselInfoTable = ({
  tab,
  RenderFields,
  getCustomCellValues,
  sectionIndex,
  formData,
  handleFieldChange,
  formErrors,
  vieableArr,
  pageType,
  viewOnly
}) => {

  const [formCount, setFormCount] = useState(1);
  const handleMultipleBerthClick = () => {
    setFormCount(prevCount => prevCount + 1);
  };
  useEffect(() => {
    if (formData?.[1]?.["opsvsv_vesselInfoCount"] > 0) {
      setFormCount(formData?.[1]?.["opsvsv_vesselInfoCount"])
    }
  }, [formData?.[1]?.["opsvsv_vesselInfoCount"]])

  return (
    <>
      {Array.from({ length: formCount }).map((_, formIndex) => (
        <React.Fragment key={`form-section-${formIndex}`} className="form-section">
          {tab.fields.map((field, fieldIndex) => {
            let isShow = true;
            // field.name = field.name +"_" +formIndex
            const uniqueFieldName = `${field.name}_${formIndex}`;
            const uniqueFieldsecondName = `${field.secondName}_${formIndex}`;
            return (
              isShow && (
                <div
                  key={`Form-default-${formIndex}-${fieldIndex}`}
                  className={"col-md-" + field.width}
                >
                  <RenderFields
                    // field={getCustomCellValues(field, "sub_table", formIndex)}
                    field={{
                      ...field,
                      name: uniqueFieldName,
                      secondName: uniqueFieldsecondName// Pass the unique name to RenderFields without changing the original field object
                    }}
                    sectionIndex={sectionIndex}
                    fieldIndex={fieldIndex}
                    formData={formData}
                    handleFieldChange={handleFieldChange}
                    formErrors={formErrors}
                    viewOnly={!vieableArr.includes(pageType) && viewOnly}
                  />
                </div>
              )
            );
          })}
          <hr />
        </React.Fragment>
      ))}

      <div className="submit_btns align-end">
        <button className='plus_icon_btn' type="button" onClick={handleMultipleBerthClick}>
          <p className='plus_icon'>+</p>
           Multiple Berth
        </button>
      </div>
    </>
  );
};

export default RenderVesselInfoTable;
