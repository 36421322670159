import React, { useEffect, useRef, useState } from "react";
import PopupOptions from "./popupOptions";
import {
  getComonCodeForCompany,
  getFormatedDate,
} from "../../services/commonFunction";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  deleteDataFromApi,
  postDataFromApi,
  putDataFromApi,
} from "../../services/commonServices";
import {
  getJIsowandactivityApi,
  getReportConfigApi,
  getCommercialCertificateListApi,
  documentUpdate,
  documentShareCreate,
  documentListApi,
  documentCreateApi,
  documentDeleteApi,
  folderCreateApi,
  ccUpdateApi,
  dsSurveyPdfApi,
  ccCertPdfApi,
  mergeFilesApi,
  masterUploadApi,
  getDSApi,
} from "../../services/api";
import { encryptDataForURL } from "../../utills/useCryptoUtils";
import PropTypes from "prop-types";
import { getCellData } from "../../services/commonFunction";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DocumentPopup from "../../views/Document/UploadFiles/DocumentPopup";
import Document from "../../formJsonData/Operations/jobinstructions/DocumentPopup.json";
import GridView from "../../views/Document/UploadFiles/DocGridView";
import { useDispatch } from "react-redux";
import Loading from "./Loading";
import { decryptDataForURL } from "../../utills/useCryptoUtils";
import {
  downLoadNonLMSCertificatePDFById,
  getNonLMSDetailsById,
} from "./commonHandlerFunction/OPscertificate/OPSCertificateHandlerFunctions";
import DeleteConfirmation from "./DeleteConfirmation";


export const selectUser = (state) => state.user;

const RenderSubListSectionWithPagination = ({
  section,
  sectionIndex,
  actions,
  responseData,
  // getAllSubListingdata,
  formConfig,
  statusCounts,
  setIsRejectPopupOpen,
  setJRFCreationType,
  setIsPopupOpen,
  // loadingTable,
  setIsOverlayLoader,
  moduleType,
  formData,
  breadcrumb,
  handleFieldChange,
  formErrors,
  viewOnly,
  actionClicked,
  JISID,
  OperationType,
  popupType,
  setPopupType,
}) => {
  let user = useSelector(selectUser);
  const navigate = useNavigate();
  const session = useSelector((state) => state.session);

  user = session.user;

  const [popupIndex, setPopupIndex] = useState(-1);
  const [sortStates, setSortStates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [sizeOfPage, setSizeOfPage] = useState(0);
  const [countPage, setCountPage] = useState(0);
  const [filteredAction, setFilteredAction] = useState(actions);
  const [subTableData, setSubTableData] = useState([]);
  const [isBottom, setIsBottom] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [selectedMultiDocs, setSelectedMultiDocs] = useState([]);
  const [dontClick, setDontClick] = useState(false);
  const [uploadPopup, setUploadPopup] = useState(false);
  // const [popupType, setPopupType] = useState("");
  const [popupJson, setPopupJson] = useState({});
  const [toggleView, setToggleView] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { EditRecordId, TMLType } = useParams();

  [EditRecordId, TMLType] = [EditRecordId, TMLType].map((item) =>
    item ? decryptDataForURL(item) : ""
  );

  const translate = t;
  const popupRef = useRef(null);
  const statusesWithColor = formConfig?.listView?.statusesWithColor;
  const divRef = useRef(null);
  const popupIntentionallyOpenedRef = useRef(false);

  /*
  Author : Yash Darshankar
  Date : 20/06/2024
  Description : This code is used to close the popup when clicked outside the popup.
  */
  const popupOptionsRef = useRef(null);

  const pageLimit =
    currentPage === totalPage
      ? Math.floor(countPage % sizeOfPage) > 3
        ? Math.floor(countPage % sizeOfPage)
        : sizeOfPage
      : sizeOfPage;

  useEffect(() => {
    setSortStates(Array(section.headers?.length).fill(false));
  }, [section]);

  useEffect(() => {
    getAllSubListingdata();
  }, []);

  const handleClick = (index, fieldName) => {
    const newSortStates = Array(section.headers?.length).fill(false);
    newSortStates[index] = !sortStates[index];
    let sortType = newSortStates[index] ? "desc" : "asc";
    getAllSubListingdata(currentPage, fieldName, sortType);
    setSortStates(newSortStates);
  };

  const getStatus = (formConfig, row) => {
    switch (moduleType) {
      case "sampleinward":
        return row["smpl_status"];
      case "testmemomain":
      case "allotment":
      case "sampleverification":
        return row["status"];
      case "sfm":
        return row["sfm_status"];
      case "internalcertificate":
        return row["status"];
      case "jobinstruction":
        return row["ji_internal_status"];
      case "vesselOperation":
        return row["status"];
      case "confirugationList":
        return row["status"];
      default:
        return row["jrf_status"];
    }
  };

  const getStatusNameValue = (cellData) => {
    let statusData = statusesWithColor;

    let filterStatusData = statusData.filter((jrfColor, jrfColorIndex) => {
      if (Array.isArray(jrfColor.status)) {
        return jrfColor.status.includes(cellData);
      } else {
        return (
          jrfColor &&
          cellData &&
          jrfColor.status.toLowerCase() == cellData.toLowerCase()
        );
      }
    });
    if (filterStatusData.length > 0) {
      filterStatusData = filterStatusData[0];
      let tempName = filterStatusData?.name;

      if (
        moduleType == "commercialCertificateList" &&
        user?.logged_in_user_info?.role === "BH" &&
        ["sent_for_approval", "saved", "posted"].includes(
          filterStatusData?.status
        )
      ) {
        tempName = "Pending";
      }
      return (
        <td key="status_list" className="status-td">
          <div
            className={
              tempName === "Pending"
                ? "table_item_sym inprocess_sym_bg"
                : "table_item_sym " + filterStatusData?.icon + "_bg"
            }
            key={"table-item"}
          >
            {tempName}
          </div>
        </td>
      );
    } else {
      return (
        <td key="status_list">
          <div className="table_item_sym" key={"table-item"} title="">
            {cellData}
          </div>
        </td>
      );
    }
  };

  const getBooleanName = (cellData) => {
    return (
      <td key="status_list">
        <div className="table_item_sym" key={"table-item"} title="">
          {cellData ? "Yes" : "No"}
        </div>
      </td>
    );
  };

  const getAllSubListingdata = async (
    pagination = "",
    fieldName = "",
    sortType = "",
    searchValue = "",
    isClearBtn = ""
  ) => {
    try {
      let querystring = "";
      if (pagination) {
        querystring += "?page=" + pagination;
      }
      let res;
      if (moduleType == "confirugationList") {
        const bodyData = {
          ji_id: EditRecordId,
          jis_id: TMLType,
        };
        res = await postDataFromApi(getReportConfigApi + querystring, bodyData);
      } else if (moduleType == "commercialCertificateList") {
        // getCommercialCertificateList();
        res = await postDataFromApi(
          getCommercialCertificateListApi + querystring
        );
        //getCommercialCertificateList
      } else if (moduleType == "JICommercialCertificateList") {
        // getCommercialCertificateList()

        let payload = {
          dl_module: "commercial_certificate",
          dl_document_reference: EditRecordId,
          is_active: true,
        };

        res = await postDataFromApi(documentListApi + querystring, payload);

        //getCommercialCertificateList
      }
      if (res?.data?.status === 200) {
        setSubTableData(res.data.data);
        setCurrentPage(res.data.current_page);
        setTotalPage(res.data.total_pages);
        setSizeOfPage(res.data.page_size);
        setCountPage(res.data.count);
        setLoadingTable(false);
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      // setLoadingTable(false);

      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoadingTable(false);
    }
  };

  const handlePrev = () => {
    getAllSubListingdata(currentPage - 1);
    setCurrentPage((prev) => prev - 1);
  };
  const handleNext = () => {
    getAllSubListingdata(currentPage + 1);
    setCurrentPage((prev) => prev + 1);
  };

  const handlePaginationButton = (pageNo) => {
    getAllSubListingdata(pageNo);
    setCurrentPage(pageNo);
  };

  const createButton = (page, currentPage, handlePaginationButton) => {
    return (
      <button
        type="button"
        className={
          currentPage === page
            ? "btn btn-danger pagination-active"
            : "btn btn-danger"
        }
        key={"page-" + page}
        onClick={() => handlePaginationButton(page)}
      >
        {page}
      </button>
    );
  };

  const getPaginationNo = () => {
    const displayedPages = [];
    const maxPagesToShow = 7; // Limit visible page buttons to avoid clutter.

    const middle = Math.floor(maxPagesToShow / 2);
    const left = Math.max(1, currentPage - middle);
    const right = Math.min(totalPage, currentPage + middle);

    if (left > 1) {
      displayedPages.push(createButton(1, currentPage, handlePaginationButton));
      if (left > 2) {
        displayedPages.push(<button key={"extra-eclipse"}>...</button>);
      }
    }

    for (let i = Math.max(1, left); i <= Math.min(totalPage, right); i++) {
      displayedPages.push(createButton(i, currentPage, handlePaginationButton));
    }

    if (right < totalPage) {
      if (right < totalPage - 1) {
        displayedPages.push(<button key={"extra-display-btn"}>...</button>);
      }
      displayedPages.push(
        createButton(totalPage, currentPage, handlePaginationButton)
      );
    }

    return displayedPages;
  };

  useEffect(() => {
    if (pageLimit - 2 === popupIndex || pageLimit - 1 === popupIndex) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  }, [popupIndex]);

  useEffect(() => {
    const handler = (event) => {
      const isLastTd = event.target.classList.contains("last-td");
      const isInsidePopup =
        popupOptionsRef.current &&
        popupOptionsRef.current.contains(event.target);
      if (
        !isInsidePopup &&
        !popupIntentionallyOpenedRef.current &&
        !isLastTd &&
        !dontClick
      ) {
        setPopupIndex(-1);
      }
      popupIntentionallyOpenedRef.current = false;
    };

    document.addEventListener("click", handler);
    setDontClick(false);
    return () => document.removeEventListener("click", handler);
  }, [dontClick]);

  const checkIsShow = (fieldName) => {
    if (moduleType === "allotment") {
      if (
        fieldName === "inward_detail" ||
        fieldName === "sample_allotedto_data"
      ) {
        if (user?.role === "LC") {
          return false;
        }
      }
    } else if (moduleType === "jrf") {
      if (fieldName === "jrf_lab_detail") {
        if (user?.role === "LR") {
          return false;
        }
      } else if (fieldName === "jrf_branch_detail") {
        if (user?.role === "BU") {
          return false;
        }
      }
    }
    return true;
  };

  const handleOnClick = (row) => {
    navigate(
      "/operation/vessel-ji-list/vessel-list/" +
      encryptDataForURL(formData[0]?.["ji_id"]) +
      "/" +
      encryptDataForURL(row["activity_master"]["activity_name"]) +
      "?OperationType=" +
      encryptDataForURL(row["activity_master"]["activity_name"]) +
      "&operationId=" +
      encryptDataForURL(row["jis_id"]) +
      "&operationStepNo=" +
      encryptDataForURL(1)
    );
  };
  const handleCertificate = (row) => {
    navigate(
      "/operation/vessel-ji-list/vessel-list/confirugation-certificate-list/" +
      encryptDataForURL(formData[0]?.["ji_id"]) +
      "/" +
      encryptDataForURL(row["jis_id"]) +
      "?OperationType=" +
      encryptDataForURL(row["activity_master"]?.activity_name)
    );
  };

  const handleCreateConfirugation = (status, RPCID) => {
    if (RPCID) {
      navigate(
        "/operation/operation-certificate/" +
        encryptDataForURL(EditRecordId) +
        "/" +
        encryptDataForURL(TMLType) +
        "?status=" +
        encryptDataForURL(status) +
        "&RPCID=" +
        encryptDataForURL(RPCID) +
        "&OperationType=" +
        encryptDataForURL(OperationType)
      );
    } else {
      navigate(
        "/operation/operation-certificate/" +
        encryptDataForURL(EditRecordId) +
        "/" +
        encryptDataForURL(TMLType) +
        "?status=" +
        encryptDataForURL(status) +
        "&OperationType=" +
        encryptDataForURL(OperationType)
      );
    }
  };
  const handleGenerateConfirugation = (row) => {
    navigate(
      "/operation/vessel-ji-list/vessel-list/confirugation-certificate/" +
      encryptDataForURL(row?.fk_jiid) +
      "/" +
      encryptDataForURL(row?.fk_jisid) +
      "/" +
      encryptDataForURL(row?.rpc_id) +
      "?OperationType=" +
      encryptDataForURL(OperationType)
    );
  };
  const handleDownloadCertificate = (row) => {
    navigate(
      "/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/" +
      encryptDataForURL(row?.fk_jiid) +
      "/" +
      encryptDataForURL(row?.cc_id) +
      "?OperationType=" +
      encryptDataForURL(OperationType)
    );
  };

  const ApproveCertificate = async (row) => {
    if (
      ![
        "Transportable moisture limit (TML)",
        "Quality Analysis",
        "Pre-Shipment (PSI)",
      ].includes(row?.activity)
    ) {
      let payload = {
        ji_id: row?.fk_jiid,
        jis_id: row?.fk_jisid,
        tenant: 1,
      };
      // let OPSDSRes = await postDataFromApi(getDSApi, payload);
      let OPSDSRes = await getNonLMSDetailsById(row.activity, payload);
      if (OPSDSRes.data.status === 200) {
        navigate(
          `/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/${encryptDataForURL(
            OPSDSRes?.data?.data?.opsvd_id
          )}/${encryptDataForURL(row.cc_id)}?status=${encryptDataForURL(
            "NonLMSApprove"
          )}&CCID=${encryptDataForURL(row.cc_id)}` +
          "&OperationType=" +
          encryptDataForURL(row.activity)
        );
      }
    } else {
      navigate(
        "/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/" +
        encryptDataForURL(row?.fk_jiid) +
        "/" +
        encryptDataForURL(row?.cc_id) +
        "/" +
        "?status=" +
        encryptDataForURL("approve") +
        "&CCID=" +
        encryptDataForURL(row?.cc_id) +
        "&OperationType=" +
        encryptDataForURL(row.activity)
      );
    }
  };

  const EditCertificate = async (row) => {
    if (
      ![
        "Transportable moisture limit (TML)",
        "Quality Analysis",
        "Pre-Shipment (PSI)",
      ].includes(row.activity)
    ) {
      navigate(
        `/operation/vessel-ji-list/vessel-list/confirugation-certificate/${encryptDataForURL(
          row.fk_jiid
        )}/${encryptDataForURL(row.fk_jisid)}/${encryptDataForURL(
          row.cc_id
        )}?status=${encryptDataForURL(
          "Edit"
        )}&OperationType=${encryptDataForURL(row.activity)}` +
        "&OperationType=" +
        encryptDataForURL(OperationType)
      );
    } else {
      navigate(
        `/operation/vessel-ji-list/vessel-list/confirugation-certificate/${encryptDataForURL(
          row.fk_jiid
        )}/${encryptDataForURL(row.fk_jisid)}/${encryptDataForURL(
          row.cc_id
        )}?status=${encryptDataForURL("Edit")}` +
        "&OperationType=" +
        encryptDataForURL(row.activity)
      );
    }
  };
  ///operation/vessel-ji-list/vessel-list/confirugation-certificate/43/121/38?status=approve
  const sendForApproval = async (row) => {
    const hash = window.location.hash;

    const params = new URLSearchParams(hash.split("?")[1]);
    const status = decryptDataForURL(params.get("status"));
    if (status === "NonLMS") {
      let payload = {
        ji_id: EditRecordId,
        jis_id: JISID,
        tenant: 1,
      };
      // let OPSDSRes = await postDataFromApi(getDSApi, payload);
      let OPSDSRes = await getNonLMSDetailsById(row.activity, payload);
      if (OPSDSRes.data.status === 200) {
        navigate(
          `/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/${encryptDataForURL(
            OPSDSRes?.data?.data?.opsvd_id
          )}/${encryptDataForURL(
            OPSDSRes?.data?.data?.cc_id
          )}?status=${encryptDataForURL("NonLMS")}` +
          "&OperationType=" +
          encryptDataForURL(row?.activity)
        );
      }
    } else {
      if (
        ![
          "Transportable moisture limit (TML)",
          "Quality Analysis",
          "Pre-Shipment (PSI)",
        ].includes(row?.activity)
      ) {
        let payload = {
          ji_id: row?.fk_jiid,
          jis_id: row?.fk_jisid,
          tenant: 1,
        };
        // let OPSDSRes = await postDataFromApi("/ops-vessel-ds/get/", payload);
        let OPSDSRes = await getNonLMSDetailsById(row.activity, payload);
        if (OPSDSRes.status === 200) {
          navigate(
            `/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/${encryptDataForURL(
              OPSDSRes?.data?.data?.opsvd_id
            )}/${encryptDataForURL(row.cc_id)}?status=${encryptDataForURL(
              "NonLMSPosted"
            )}&CCID=${encryptDataForURL(row.cc_id)}` +
            "&OperationType=" +
            encryptDataForURL(row?.activity)
          );
        }
      } else {
        navigate(
          `/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/${encryptDataForURL(
            row?.fk_jiid
          )}/${encryptDataForURL(row.cc_id)}?status=${encryptDataForURL(
            "posted"
          )}&CCID=${encryptDataForURL(row.cc_id)}` +
          "&OperationType=" +
          encryptDataForURL(row?.activity)
        );
      }
    }
  };

  const handlePublish = async (row) => {
    //Use This Dynamic Folder ID
    let folderPayload = {
      data: {
        // "fd_name": "27C2425A01VL0028"
        fd_name: row?.cc_refencenumber,
      },
      parent_folder: "commercial_certificate",
    };
    let folderRes = await postDataFromApi(folderCreateApi, folderPayload);
    let FolderID;
    setLoadingTable(true);

    if (folderRes.data.status === 201 || folderRes.data.status === 200) {
      FolderID = folderRes?.data?.data.fd_id;
    } else {
      FolderID = folderRes?.data?.message?.existing_folder_id;
    }
    if (
      FolderID &&
      (folderRes?.data.status === 200 || folderRes?.data?.status === 400)
    ) {
      let payload, generateCertificateResponse;
      if (
        ![
          "Transportable moisture limit (TML)",
          "Quality Analysis",
          "Pre-Shipment (PSI)",
        ].includes(row?.activity)
      ) {
        let payload = {
          ji_id: row?.fk_jiid,
          jis_id: row?.fk_jisid,
          tenant: 1,
        };
        // let OPSDSRes = await postDataFromApi(getDSApi, payload);
        let OPSDSRes = await getNonLMSDetailsById(row.activity, payload);
        if (OPSDSRes.data.status === 200) {
          // payload = {
          //   opsvd_id: OPSDSRes?.data?.data?.opsvd_id,
          // };

          // generateCertificateResponse = await postDataFromApi(
          //   dsSurveyPdfApi,
          //   payload,
          //   "",
          //   true,
          //   "",
          //   ""
          // );
          generateCertificateResponse = await downLoadNonLMSCertificatePDFById(
            row?.activity,
            OPSDSRes?.data?.data?.opsvd_id,
            row?.cc_id
          );
        }
      } else {
        payload = {
          ji_id: row?.fk_jiid,
          cc_id: row?.cc_id,
        };
        generateCertificateResponse = await postDataFromApi(
          ccCertPdfApi,
          payload,
          "",
          true,
          "",
          ""
        );
      }

      if (
        generateCertificateResponse &&
        generateCertificateResponse.data &&
        generateCertificateResponse.data.status === 200
      ) {
        const pdfBlob = new Blob([generateCertificateResponse.data], {
          type: "application/pdf",
        });
        let payload = new FormData();
        payload.append("document", pdfBlob, "certificate.pdf");
        payload.append("model_type ", "commercial_certificate");
        payload.append("bypass_file_size_check ", true);
        payload.append("sub_folder", 6);
        let uploadResponse = await postDataFromApi(
          masterUploadApi,
          payload,
          "TRUE"
        );

        if (uploadResponse.data.status === 200) {
          let payload = {
            data: {
              dl_folder: FolderID,
              dl_sub_folder: 6,
              dl_module: "commercial_certificate",
              dl_document_name:
                row?.cc_refencenumber || row?.cc_certificatenumber,
              dl_document_reference: row?.fk_jiid,
              dl_type: "Document Type",
              dl_show_to_all: true,
              dl_s3_url: uploadResponse.data?.data?.document,
              dl_version: "1.0",
              dl_file_type: "Certificate",
              dl_date_uploaded: "2023-10-23T12:00:00Z",
              dl_status: "Active",
              dl_assigned_to: "Assigned User",
              dl_discription:
                row?.activity ||
                row?.cc_topheader ||
                row?.cc_refencenumber ||
                row?.cc_certificatenumber,
            },
          };
          let documentResponse = await postDataFromApi(
            documentCreateApi,
            payload
          );

          if (documentResponse.data.status === 200) {
            PublishCertificate(row.cc_id, "published");
          } else {
            setLoadingTable(false);
          }
        } else {
          toast.error(
            uploadResponse?.message ||
            uploadResponse?.data?.message ||
            translate("loginPage.unExpectedError"),
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setLoadingTable(false);
        }
      } else {
        toast.error(
          generateCertificateResponse?.message ||
          generateCertificateResponse?.data?.message ||
          translate("loginPage.unExpectedError"),
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setLoadingTable(false);
      }
    } else {
      setLoadingTable(false);
    }
  };
  const PublishCertificate = async (CCID, status) => {
    let payload = {
      cc_id: CCID,
      commercial_certificate: {
        status: status,
      },
    };
    let res = await putDataFromApi(ccUpdateApi, payload);
    if (res?.data?.status === 200) {
      // navigate("/operation/commercial-certificate-list");
      getAllSubListingdata();
      setLoadingTable(false);
    } else {
      setLoadingTable(false);
    }
  };

  const handleDownloadCertificateAfterPublish = async (row) => {
    let payload, generateCertificateResponse;
    if (
      ![
        "Transportable moisture limit (TML)",
        "Quality Analysis",
        "Pre-Shipment (PSI)",
      ].includes(row?.activity)
    ) {
      let payload = {
        ji_id: row?.fk_jiid,
        jis_id: row?.fk_jisid,
        tenant: 1,
      };
      // let OPSDSRes = await postDataFromApi(getDSApi, payload);
      let OPSDSRes = await getNonLMSDetailsById(row.activity, payload);
      if (OPSDSRes.data.status === 200) {
        // payload = {
        //   opsvd_id: OPSDSRes?.data?.data?.opsvd_id,
        // };

        // generateCertificateResponse = await postDataFromApi(
        //   dsSurveyPdfApi,
        //   payload,
        //   "",
        //   true,
        //   "",
        //   ""
        // );
        generateCertificateResponse = await downLoadNonLMSCertificatePDFById(
          row?.activity,
          OPSDSRes?.data?.data?.opsvd_id,
          row?.cc_id
        );
      }
    } else {
      payload = {
        ji_id: row?.fk_jiid,
        cc_id: row?.cc_id,
      };
      generateCertificateResponse = await postDataFromApi(
        ccCertPdfApi,
        payload,
        "",
        true,
        "",
        ""
      );
    }

    if (generateCertificateResponse && generateCertificateResponse.data) {
      const pdfBlob = new Blob([generateCertificateResponse.data], {
        type: "application/pdf",
      });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create a link element
      const link = document.createElement("a");
      link.href = pdfUrl;

      // Set the filename for the download
      link.download = row?.activity + "_" + row?.cc_certificatenumber;
      // Programmatically click the link to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link and revoking the object URL
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
    }
  };

  const getConfigTypeCellContent = (row, index) => {
    let cellContent = "--"; // Default content

    if (row["is_consortium"] && row["is_dual_port"]) {
      cellContent = "Consortium, Dual Port";
    } else if (row["is_consortium"]) {
      cellContent = "Consortium";
    } else if (row["is_dual_port"]) {
      cellContent = "Dual Port";
    }

    return <td key={`cellIndex${index}`}>{cellContent}</td>;
  };

  const handleMultiFile = (e, doc) => {
    const docIndex = doc.dl_id;
    setSelectedRow((prev) => {
      if (prev.includes(docIndex)) {
        return prev.filter((index) => index !== docIndex);
      } else {
        return [...prev, docIndex];
      }
    });

    setSelectedMultiDocs((prev) => {
      // Check if doc with this ID is already in selectedMultiDocs
      const exists = prev.some((item) => item.dl_id === docIndex);

      if (exists) {
        // If it exists, filter it out (remove from the array)
        return prev.filter((item) => item.dl_id !== docIndex);
      } else {
        // If it doesn't exist, add it to the array
        return [...prev, doc];
      }
    });
  };
  const [multipleUrls, setMultipleUrls] = useState([]);
  useEffect(() => {
    let selectedUrls = subTableData
      .filter((doc) => selectedRow.includes(doc.dl_id)) // Filter the documents based on selected dl_id
      .map((doc) => doc.dl_s3_url); // Extract the dl_s3_url for the matched docs

    setMultipleUrls(selectedUrls);
  }, [subTableData, selectedRow]);

  const handleMergeFiles = async () => {
    let selectedUrls = subTableData
      .filter((doc) => selectedRow.includes(doc.dl_id)) // Filter the documents based on selected dl_id
      .map((doc) => doc.dl_s3_url); // Extract the dl_s3_url for the matched docs

    // Create the payload with the s3 URLs
    let payload = {
      s3_files: selectedUrls,
    };

    // Send the payload to the API

    let res = await postDataFromApi(mergeFilesApi, payload, "", true, "", "");

    if (res && res.data && res.data.status === 200) {
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      let Imagepayload = new FormData();
      Imagepayload.append("document", pdfBlob, "certificate.pdf");
      Imagepayload.append("model_type ", "commercial_certificate");
      Imagepayload.append("bypass_file_size_check ", "True");
      let uploadResponse = await postDataFromApi(
        masterUploadApi,
        Imagepayload,
        "TRUE"
      );

      if (uploadResponse.data.status === 200) {
        let payload = {
          data: {
            dl_folder: 1,
            dl_module: "commercial_certificate",
            dl_document_name: formData[1].dl_document_name,
            dl_discription: formData[1].dl_discription,
            dl_document_reference: EditRecordId,
            dl_type: "Document Type",
            dl_show_to_all: false,
            dl_s3_url:
              "https://18northawsbucket.s3.ap-south-1.amazonaws.com/LLP_STAMP.png",
            dl_version: "1.0",
            dl_date_uploaded: "2023-04-01T12:00:00Z",
            dl_status: "Active",
            dl_assigned_to: "Assigned User",
          },
        };

        let DocResonse = await postDataFromApi(documentCreateApi, payload);
        if (DocResonse.data.status === 200) {
          getAllSubListingdata();
        }
      } else {
        toast.error(
          uploadResponse?.message ||
          uploadResponse?.data?.message ||
          translate("loginPage.unExpectedError"),
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  };
  const handleDescriptionOrNameChange = async (docType) => {
    let payload = {
      id: selectedDoc?.dl_id,
    };
    if (docType === "Rename") {
      payload.data = { dl_document_name: formData[1].dl_document_name };
    } else {
      payload.data = { dl_discription: formData[1].dl_discription };
    }

    let res = await putDataFromApi(documentUpdate, payload);
    if (res.data.status === 200) {
      setUploadPopup(false);
      getAllSubListingdata();
    } else {
      toast.error(
        res?.data?.message || translate("loginPage.unExpectedError"),
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };
  //handleDescriptionOrNameChange
  const handleUploadDocument = async () => {
    if (popupType === "Merge") {
      handleMergeFiles();
    } else if (popupType === "Description") {
      handleDescriptionOrNameChange("Description");
    } else if (popupType === "Rename") {
      handleDescriptionOrNameChange("Rename");
    } else if (popupType === "Share") {
      handleSharedFile();
    } else if (popupType === "Upload") {
      handleSharedFile();
    } else if (popupType === "UploadInfo") {
      handleCreateFile();
    }
  };
  const [fileUrl, setFileUrl] = useState("");
  const handleCreateFile = async () => {
    let folderPayload = {
      data: {
        // "fd_name": "27C2425A01VL0028"
        fd_name: formData[0]?.ji_reference_number,
      },
      parent_folder: "commercial_certificate",
    };
    let folderRes = await postDataFromApi(folderCreateApi, folderPayload);
    let FolderID;
    setLoadingTable(true);

    if (folderRes.data.status === 201 || folderRes.data.status === 200) {
      FolderID = folderRes?.data?.data.fd_id;
    } else {
      FolderID = folderRes?.data?.message?.existing_folder_id;
    }
    if (FolderID) {
      let payload = {
        data: {
          dl_folder: FolderID,
          dl_sub_folder: 6,
          dl_module: "commercial_certificate",
          dl_document_name: formData[1].dl_document_name,
          dl_discription: formData[1].dl_discription,
          dl_document_reference: EditRecordId,
          dl_type: "Document Type",
          dl_show_to_all: true,
          dl_s3_url: fileUrl,
          dl_version: "1.0",
          dl_file_type: "Uploaded",
          dl_date_uploaded: "2023-10-23T12:00:00Z",
          dl_status: "Active",
          dl_assigned_to: "Assigned User",
        },
      };

      try {
        let documentResponse = await postDataFromApi(
          documentCreateApi,
          payload
        );
        if (documentResponse?.data?.status === 200) {
          setUploadPopup(false);
          getAllSubListingdata();
          setTimeout(() => {
            toast.success(
              documentResponse.data?.message || "Document Created Successfully"
            );
          }, 250);
        }
      } finally {
        // setIsLoading(false);
        setLoadingTable(false);
      }
    }
  };

  const handleSharedFile = async () => {
    const sharePayload = {
      document_id: selectedDoc.dl_id,
      data: [
        {
          ds_document: selectedDoc.dl_id,
          ds_shared_with: formData[1]?.ds_shared_with,
          ds_share_date: "2023-04-01T12:00:00Z",
          ds_restriction_dwonload: "true",
          ds_download_limit: 10,
          ds_download_count: 0,
          ds_restriction_view: false,
          ds_restriction_print: false,
          ds_expiry_date: "2023-12-31T23:59:59Z",
        },
      ],
    };
    try {
      let res = await postDataFromApi(documentShareCreate, sharePayload);
      if (res?.data?.status === 200) {
        setUploadPopup(false);
        setTimeout(() => {
          toast.success(res.data?.message || "Document Shared Successfully");
        }, 250);
      }
    } finally {
      // setIsLoading(false);
    }
  };

  const deleteDocument = async () => {
    let payload = {
      id: subTableData[popupIndex].dl_id,
    };

    let res = await deleteDataFromApi(documentDeleteApi, payload);

    // let deleteBody = {
    //   "rpc_id": row?.rpc_id,
    //   "ji_id": row?.fk_jiid,
    //   "jis_id": row?.fk_jisid
    // }
    // let res = await deleteDataFromApi(reportConfigDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    // setIsDelete(false);
    // setPopupIndex(-1);
    // getAllListingData();
    getAllSubListingdata();
  };

  useEffect(() => {
    if (uploadPopup === false) {
      setPopupType("");
    }
  }, [uploadPopup]);

  useEffect(() => {
    if (popupType === "Info") {
      setUploadPopup(true);
      setPopupJson(Document.upload.infoJson);
    } else if (popupType === "Rename") {
      setUploadPopup(true);
      setPopupJson(Document.upload.changeNameJson);
    } else if (popupType === "Description") {
      setUploadPopup(true);
      setPopupJson(Document.upload.changeDescriptionJson);
    } else if (popupType === "Upload") {
      setUploadPopup(true);
      setPopupJson(Document.upload.changeDescriptionJson);
    } else if (popupType === "Share") {
      setUploadPopup(true);
      setPopupJson(Document.upload.shareJson);
    } else if (popupType === "Download") {
      const url = subTableData[popupIndex].dl_s3_url;
      const link = document.createElement("a");
      link.href = url;
      link.download = subTableData[popupIndex].dl_document_name + ".pdf"; // Optional: specify the default file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (popupType === "Delete") {
      // deleteDocument();
      setIsDelete(true);
    } else if (popupType === "Merge") {
      setUploadPopup(true);
      setPopupJson(Document.upload.infoJson);
    }
  }, [popupType]);

  return loadingTable ? (
    <Loading />
  ) : (
    <div key={sectionIndex} className="row my-2 mx-0 renderList_header_table">
      {isDelete && <DeleteConfirmation
        isOpen={uploadPopup}
        handleClose={setUploadPopup}
        handleConfirm={() => deleteDocument()}
        popupMessage={"Confirmation !"}
        popupHeading={"Are you sure you want to delete ?"}
      // saveClicked={saveClicked}
      // isOverlayLoader={loader}
      />}
      {uploadPopup && (
        <DocumentPopup
          setUploadPopup={setUploadPopup}
          sectionIndex={sectionIndex}
          formData={formData}
          handleFieldChange={handleFieldChange}
          formErrors={formErrors}
          viewOnly={viewOnly}
          actionClicked={actionClicked}
          handleUploadDocument={handleUploadDocument}
          popupType={popupType}
          setPopupType={setPopupType}
          popupJson={popupJson}
          selectedDoc={selectedDoc}
          fileUrl={fileUrl}
          setFileUrl={setFileUrl}
        />
      )}

      <div className="renderList_table_container">
        {" "}
        <div className="renderList_table" ref={divRef}>
          <div className="renderList_table_heading">
            <h2>{breadcrumb[breadcrumb.length - 1].title}</h2>
            {moduleType == "confirugationList" && (
              <button
                type="button"
                className="submitBtn CreateConfirugationBtn"
                onClick={() => handleCreateConfirugation("saved")}
              >
                + Create Confirugation
              </button>
            )}

            {moduleType == "JICommercialCertificateList" && (
              <div className="docSearchNav1">
                <div
                  onClick={() => {
                    setToggleView((Prev) => !Prev);
                  }}
                >
                  <i
                    className={
                      !toggleView
                        ? "bi bi-list-ul viewIconNav1"
                        : "bi bi-grid viewIconNav1"
                    }
                  ></i>
                  <span>{!toggleView ? "List View" : "Grid View"}</span>
                </div>
              </div>
            )}
          </div>
          {!toggleView ? (
            <GridView
              documentList={subTableData}
              // listView={listView}
              setSelectedDoc={setSelectedDoc}
              popupIntentionallyOpenedRef={popupIntentionallyOpenedRef}
              setPopupIndex={setPopupIndex}
              // rowIndex={rowIndex}
              popupOptionsRef={popupOptionsRef}
              popupIndex={popupIndex}
              PopupOptions={PopupOptions} // Check that PopupOptions is imported correctly
              section={section}
              filteredAction={filteredAction}
              getAllSubListingdata={getAllSubListingdata}
              moduleType={moduleType}
              formConfig={formConfig}
              responseData={responseData}
              isBottom={isBottom}
              getStatus={getStatus}
              setDontClick={setDontClick}
              setPopupType={setPopupType}
              handleMultiFile={handleMultiFile}
            // row={row}
            />
          ) : (
            <table className="table table-white responsive borderless no-wrap align-middle list configureList">
              <thead>
                <tr className="border-top">
                  {moduleType === "JICommercialCertificateList" && <th></th>}
                  <th>Sr. No.</th>
                  {section.headers?.map(
                    (header, headerIndex) =>
                      checkIsShow(header.name) && (
                        <th
                          key={"HeaderIndex -" + headerIndex}
                          colSpan={header.colSpan ?? 1}
                          onClick={() =>
                            handleClick(headerIndex, header?.sortName)
                          }
                        >
                          {header?.label}
                        </th>
                      )
                  )}
                  {/* {moduleType !== "JICommercialCertificateList" && */}
                  <th>Actions</th>
                  {/* } */}
                </tr>
              </thead>
              <tbody>
                {subTableData?.map((row, rowIndex) => (
                  <tr
                    key={"rowIndex-" + rowIndex}
                    // className={getTdBorderColor(row)}
                    className="border-top"
                  >
                    {moduleType === "JICommercialCertificateList" && (
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleMultiFile(e, row)}
                        />
                      </td>
                    )}

                    <td key={"rowIndex-" + rowIndex + "1"}>{rowIndex + 1}</td>
                    {section.headers?.map((header, index) => {
                      if (!checkIsShow(header.name)) {
                        return null;
                      }
                      let cellData = formData[0]?.[header?.name];
                      if (!cellData) {
                        cellData = row[header?.name];
                      }
                      if (header?.fieldName === "status") {
                        return getStatusNameValue(row[header?.name]);
                      } else if (
                        header?.fieldName === "rpc_is_wght_avg" ||
                        header?.fieldName === "rpc_is_smpl_type"
                      ) {
                        return getBooleanName(row[header?.name]);
                      } else if (header?.fieldName === "config_type") {
                        return getConfigTypeCellContent(row, index);
                      } else if (header?.fromType === "array") {
                        return (
                          <td key={"cellIndex" + index}>
                            {cellData
                              ? header.type === "date"
                                ? getFormatedDate(
                                  cellData[header?.fieldName],
                                  1
                                )
                                : header.fieldName === "company_code"
                                  ? getComonCodeForCompany(
                                    cellData[header?.fieldName]
                                  )
                                  : getCellData(cellData[header?.fieldName])
                              : "--"}
                          </td>
                        );
                      } else {
                        return (
                          <td key={"cellIndex" + index} title={cellData}>
                            {header.type === "date"
                              ? getFormatedDate(cellData, 1)
                              : getCellData(cellData)}
                          </td>
                        );
                      }
                    })}
                    <td className="exclude-click last-td" ref={popupRef}>
                      <div className="actionColumn maxWidth">
                        <button
                          type="button"
                          onClick={() => {
                            popupIntentionallyOpenedRef.current = true; // Indicate the popup was intentionally opened
                            setPopupIndex((prevIndex) => {
                              return prevIndex === rowIndex ? -1 : rowIndex;
                            });
                            setSelectedDoc(row);
                          }}
                          aria-label="Toggle popup"
                          className="invisible-button"
                        >
                          <i className="bi bi-three-dots-vertical"></i>
                        </button>
                        <span ref={popupOptionsRef}>
                          {popupIndex === rowIndex ? (
                            <PopupOptions
                              section={section}
                              popupActions={filteredAction}
                              setPopupIndex={setPopupIndex}
                              id={
                                moduleType === "sampleinward"
                                  ? row["smpl_jrf_id"]
                                  : row["jrf_id"]
                              }
                              sampleInwardFormId={row["smpl_inwrd_id"]}
                              row={row}
                              formConfig={formConfig}
                              model={responseData.model}
                              isBottom={isBottom}
                              status={getStatus(formConfig, row)}
                              setDontClick={setDontClick}
                              from="subListTable"
                              setPopupType={setPopupType}
                              getAllListingData={getAllSubListingdata}
                            />
                          ) : null}
                        </span>
                        <div className="listActionBtns">
                          {moduleType === "commercialCertificateList" ? (
                            <div className="actionColumn maxWidth d-flex confirugationListActionColumn">
                              {[
                                "saved",
                                "sent_for_approval",
                                "posted",
                              ].includes(row["status"]) &&
                                user?.logged_in_user_info?.role === "BH" && (
                                  <button
                                    type="button"
                                    className="iconBtn"
                                    onClick={() => ApproveCertificate(row)}
                                  >
                                    Approve Certificate
                                  </button>
                                )}
                              {row["status"] === "saved" &&
                                user?.logged_in_user_info?.role === "BU" &&
                                moduleType !==
                                "JICommercialCertificateList" && (
                                  <button
                                    type="button"
                                    className="iconBtn"
                                    onClick={() => EditCertificate(row)}
                                  >
                                    Edit
                                  </button>
                                )}
                              {row["status"] === "posted" &&
                                user?.logged_in_user_info?.role === "BU" && (
                                  <button
                                    type="button"
                                    className="iconBtn"
                                    onClick={() => sendForApproval(row)}
                                  >
                                    Send For Approval
                                  </button>
                                )}
                              {row["status"] === "approved" &&
                                user?.logged_in_user_info?.role === "BU" && (
                                  <button
                                    type="button"
                                    className="iconBtn"
                                    onClick={() => handlePublish(row)}
                                  >
                                    Publish
                                  </button>
                                )}
                              {row["status"] === "published" &&
                                user?.logged_in_user_info?.role === "BU" && (
                                  <button
                                    type="button"
                                    className="iconBtn"
                                    // onClick={() => handlePublish(row)}
                                    onClick={() =>
                                      handleDownloadCertificateAfterPublish(row)
                                    }
                                  >
                                    Download
                                  </button>
                                )}
                            </div>
                          ) : moduleType !== "confirugationList" ? (
                            <div className="actionColumn maxWidth d-flex confirugationListActionColumn">
                              {/* <button
                                type="button"
                                onClick={() => {
                                  popupIntentionallyOpenedRef.current = true; // Indicate the popup was intentionally opened
                                  setPopupIndex((prevIndex) => {
                                    return prevIndex === rowIndex
                                      ? -1
                                      : rowIndex;
                                  });
                                  setSelectedDoc(row);
                                }}
                                aria-label="Toggle popup"
                                className="invisible-button"
                              >
                                <i className="bi bi-three-dots-vertical"></i>
                              </button> */}
                              {/* <span ref={popupOptionsRef}>
                                {popupIndex === rowIndex ? (
                                  <PopupOptions
                                    section={section}
                                    popupActions={filteredAction}
                                    setPopupIndex={setPopupIndex}
                                    getAllSubListingdata={getAllSubListingdata}
                                    id={
                                      moduleType === "sampleinward"
                                        ? row["smpl_jrf_id"]
                                        : row["jrf_id"]
                                    }
                                    sampleInwardFormId={row["smpl_inwrd_id"]}
                                    row={row}
                                    formConfig={formConfig}
                                    model={responseData.model}
                                    isBottom={isBottom}
                                    status={getStatus(formConfig, row)}
                                    setDontClick={setDontClick}
                                    setPopupType={setPopupType}
                                  />
                                ) : null}
                              </span> */}
                              {!["posted", "input-completed"].includes(
                                row["status"]
                              ) &&
                                moduleType !==
                                "JICommercialCertificateList" && (
                                  <div className="">
                                    <button
                                      type="button"
                                      className="iconBtn"
                                      onClick={() => handleOnClick(row)}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                )}
                              {["posted", "input-completed"].includes(
                                row["status"]
                              ) && (
                                  <div>
                                    <button
                                      type="button"
                                      className="iconBtn"
                                      onClick={() => handleCertificate(row)}
                                    >
                                      Certificate
                                    </button>
                                  </div>
                                )}
                            </div>
                          ) : (
                            <div className="actionColumn maxWidth d-flex gap-8">
                              {row["status"] === "saved" && (
                                <div>
                                  <button
                                    type="button"
                                    className="iconBtn"
                                    onClick={() =>
                                      handleCreateConfirugation(
                                        row["status"],
                                        row["rpc_id"]
                                      )
                                    }
                                  >
                                    Configure Certificate
                                  </button>
                                </div>
                              )}
                              {row["status"] === "configured" && (
                                <div>
                                  <button
                                    type="button"
                                    className="iconBtn"
                                    onClick={() =>
                                      handleGenerateConfirugation(row)
                                    }
                                  >
                                    Generate
                                  </button>
                                </div>
                              )}

                              {row["status"] === "completed" && (
                                <div>
                                  <button
                                    type="button"
                                    className="iconBtn"
                                    onClick={() =>
                                      handleDownloadCertificate(row)
                                    }
                                  >
                                    Download
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {moduleType === "JICommercialCertificateList" && (
          <div className="submit_btns align-end">
            {multipleUrls?.length > 1 && (
              <button
                onClick={() => setPopupType("Merge")}
                className="submitBtn"
                type="button"
              >
                Merge
              </button>
            )}
            {multipleUrls?.length > 0 && (
              <button
                onClick={() => {
                  dispatch({
                    type: "SHARED_FILES",
                    selectedMultiDocs: selectedMultiDocs,
                  });
                  navigate("/operation/ShareFiles");
                }}
                className="submitBtn"
                type="button"
              >
                Share Files
              </button>
            )}
          </div>
        )}
        <div className="previous_next_btns">
          <button
            type="button"
            onClick={() => handlePrev()}
            disabled={currentPage === 1}
            className={
              (currentPage === 1 ? "disabled_btn" : null) + " previous_btn"
            }
            aria-label="Previous Page"
          >
            <i className="bi bi-chevron-left" aria-hidden="true"></i>
          </button>

          {getPaginationNo()}

          <button
            type="button"
            onClick={() => handleNext()}
            disabled={currentPage === totalPage}
            className={
              (currentPage === totalPage ? "disabled_btn" : null) + " next_btn"
            }
            aria-label="Next Page"
          >
            <i className="bi bi-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
RenderSubListSectionWithPagination.propTypes = {
  section: PropTypes.string,
  sectionIndex: PropTypes.number,
  actions: PropTypes.arrayOf(PropTypes.object),
  responseData: PropTypes.object,
  // getAllSubListingdata: PropTypes.func,
  formConfig: PropTypes.object,
  statusCounts: PropTypes.object,
  setIsRejectPopupOpen: PropTypes.func,
  setJRFCreationType: PropTypes.func,
  setIsPopupOpen: PropTypes.func,
  // loadingTable: PropTypes.bool,
  setIsOverlayLoader: PropTypes.func,
  moduleType: PropTypes.string,
  formData: PropTypes.object,
};
export default RenderSubListSectionWithPagination;
